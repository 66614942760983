import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { initialState } from './mainPrincipalTypesState';
import { retrieveMainPrincipalTypesAsync } from './actions';
import { onRetrieveMainPrincipalTypes } from './reducers';

export default reducerWithInitialState(initialState)
	.case(
		retrieveMainPrincipalTypesAsync.started,
		onRetrieveMainPrincipalTypes.started
	)
	.case(retrieveMainPrincipalTypesAsync.done, onRetrieveMainPrincipalTypes.done)
	.case(
		retrieveMainPrincipalTypesAsync.failed,
		onRetrieveMainPrincipalTypes.failed
	);
