import { FetchStatus } from 'services/api/apiTypes';
import { InterdepartmentalService } from 'services/api/interdepartmentalService/interdepartmentalServiceTypes';

export interface InterdepartmentalServicesState {
	hasMore: boolean;
	data: Array<InterdepartmentalService>;
	fetchStatuses: {
		retrieve: FetchStatus;
		delete: FetchStatus;
		create: FetchStatus;
		update: FetchStatus;
	};
}

export const initialState: InterdepartmentalServicesState = {
	hasMore: true,
	data: [],
	fetchStatuses: {
		retrieve: FetchStatus.IDLE,
		delete: FetchStatus.IDLE,
		create: FetchStatus.IDLE,
		update: FetchStatus.IDLE
	}
};
