import { RetrieveMainPrincipalTypesResponse } from 'services/api/mainPrincipalTypes/mainPrincipalTypesServiceTypes';
import actionCreator from '../mainPrincipalTypesActionCreator';

const ACTION_NAME = 'RETRIEVE_MAIN_PRINCIPAL_TYPES';

export const retrieveMainPrincipalTypesAsync = actionCreator.async<
	null,
	RetrieveMainPrincipalTypesResponse,
	Error
>(ACTION_NAME);

export const retrieveMainPrincipalTypes = actionCreator(ACTION_NAME);
