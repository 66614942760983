import {
	CreateCostCentrePayload,
	CreateCostCentreResponse
} from 'services/api/costCentre/costCentreServiceTypes';
import { CostCentreState } from '../costCentreState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { AxiosError } from 'axios';

export const onCreateCostCentre = makeFetchStatusReducers<
	CostCentreState,
	'fetchStatuses',
	CreateCostCentrePayload,
	CreateCostCentreResponse,
	AxiosError
>('fetchStatuses', 'create', {
	doneReducer: (state, { params, result }) => {
		const newCostCentre = { ...params.costCentre, id: result.id };
		return {
			...state,
			data: [...state.data, newCostCentre]
		};
	}
});
