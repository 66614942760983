import {
	UpdateCostCentrePayload,
	CostCentre
} from 'services/api/costCentre/costCentreServiceTypes';
import actionCreator from '../costCentresActionCreator';

const ACTION_NAME = 'UPDATE_COST_CENTRE';

export const updateCostCentreAsync = actionCreator.async<
	UpdateCostCentrePayload,
	CostCentre,
	Error
>(ACTION_NAME);

export const updateCostCentre = actionCreator<UpdateCostCentrePayload>(
	ACTION_NAME
);
