import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { InterdepartmentalServicesState } from '../interdepartmentalServicesState';
import {
	UpdateInterdepartmentalServiceRequest,
	InterdepartmentalService
} from 'services/api/interdepartmentalService/interdepartmentalServiceTypes';
import { map } from 'lodash';

export const onUpdateInterdepartmentalService = makeFetchStatusReducers<
	InterdepartmentalServicesState,
	'fetchStatuses',
	UpdateInterdepartmentalServiceRequest,
	InterdepartmentalService
>('fetchStatuses', 'update', {
	doneReducer: (state, { result }) => ({
		...state,
		data: map(
			state.data,
			(interdepartmentalService: InterdepartmentalService) =>
				interdepartmentalService.id === result.id
					? result
					: interdepartmentalService
		)
	})
});
