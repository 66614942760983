import {
	DeleteInterdepartmentalServiceRequest,
	DeleteInterdepartmentalServiceResponse
} from 'services/api/interdepartmentalService/interdepartmentalServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { deleteInterdepartmentalServiceAsync } from '../actions';
import Api from 'services/api';

export default makeTakeLatestWatcher<
	DeleteInterdepartmentalServiceRequest,
	DeleteInterdepartmentalServiceResponse,
	Error
>({
	api: Api.InterdepartmentalServices.deleteInterdepartmentalService,
	async: deleteInterdepartmentalServiceAsync
});
