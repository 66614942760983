import { FinanceState } from '../financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortJobServiceVarianceComment } from 'services/api/finance/financeServiceTypes';
import { Success } from 'typescript-fsa';

export const retrieveVarianceComments = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	string,
	PortJobServiceVarianceComment
>('fetchStatuses', 'retrieveVarianceComments', {
	doneReducer: (
		state: FinanceState,
		action: Success<string, PortJobServiceVarianceComment>
	) => {
		return {
			...state,
			context: {
				...state.context,
				portJobServiceVarianceComment: action.response?.data
			}
		};
	}
});
