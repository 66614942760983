import { MainPrincipalTypesState } from '../mainPrincipalTypesState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';
import { RetrieveMainPrincipalTypesResponse } from 'services/api/mainPrincipalTypes/mainPrincipalTypesServiceTypes';

const onRetrieveMainPrincipalTypesSuccess = (
	state: MainPrincipalTypesState,
	action: Success<null, RetrieveMainPrincipalTypesResponse>
) => ({
	...state,
	data: action.result.elements
});

export const onRetrieveMainPrincipalTypes = makeFetchStatusReducers<
	MainPrincipalTypesState,
	'fetchStatuses',
	null,
	RetrieveMainPrincipalTypesResponse
>('fetchStatuses', 'retrieve', {
	doneReducer: onRetrieveMainPrincipalTypesSuccess
});
