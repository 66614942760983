import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { EntitiesState } from '../entitiesState';
import {
	DeleteEntityRequest,
	DeleteEntityResponse
} from 'services/api/entities/entitiesServiceTypes';
import { Success } from 'typescript-fsa';

export const onDeleteEntity = makeFetchStatusReducers<
	EntitiesState,
	'fetchStatuses',
	DeleteEntityRequest,
	DeleteEntityResponse
>('fetchStatuses', 'delete', {
	doneReducer: (
		state: EntitiesState,
		action: Success<DeleteEntityRequest, DeleteEntityResponse>
	): EntitiesState => ({
		...state,
		data: state.data.filter(entity => entity.id !== action.params.id) || []
	})
});
