import { all, fork } from 'redux-saga/effects';
import retrieveRevenueInvoiceSaga from './retrieveRevenueInvoiceSaga';
import deleteRevenueInvoiceModalSaga from './deleteRevenueInvoiceModalSaga';
import deleteRevenueInvoiceSaga from './deleteRevenueInvoiceSaga';
import createRevenueInvoiceSaga from './createRevenueInvoiceSaga';
import updateRevenueInvoiceSaga from './updateRevenueInvoiceSaga';

export default function*() {
	yield all([fork(retrieveRevenueInvoiceSaga)]);
	yield all([fork(createRevenueInvoiceSaga)]);
	yield all([fork(updateRevenueInvoiceSaga)]);
	yield all([fork(deleteRevenueInvoiceModalSaga)]);
	yield all([fork(deleteRevenueInvoiceSaga)]);
}
