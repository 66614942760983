import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { deleteRevenueInvoiceAsync } from '../actions';
import Api from 'services/api';
import {
	DeleteRevenueInvoiceRequest,
	DeleteRevenueInvoiceResponse
} from 'services/api/revenueInvoice/revenueInvoiceServiceTypes';

export default makeTakeLatestWatcher<
	DeleteRevenueInvoiceRequest,
	DeleteRevenueInvoiceResponse,
	Error
>({
	api: Api.RevenueInvoice.deleteRevenueInvoice,
	async: deleteRevenueInvoiceAsync
});
