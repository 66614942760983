import {
	all,
	fork,
	put,
	race,
	select,
	take,
	takeLatest
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
	deleteRevenueInvoice,
	DeleteRevenueInvoiceActionParams,
	deleteRevenueInvoiceAsync,
	deleteRevenueInvoiceModal
} from '../actions';
import { getRevenueInvoiceById } from '../selectors';
import { Action } from 'typescript-fsa';
import { closeModal, openModal } from 'store/modals/actions';
import { RevenueInvoice } from '../../../services/api/revenueInvoice/revenueInvoiceServiceTypes';

export function* worker({
	payload: { id }
}: Action<DeleteRevenueInvoiceActionParams>): SagaIterator {
	const revenueInvoice: RevenueInvoice = yield select(
		getRevenueInvoiceById,
		id
	);
	const revenueInvoiceName = revenueInvoice ? revenueInvoice.title : '';

	yield put(
		openModal({
			name: 'deleteRevenueInvoice',
			type: 'confirm',
			data: {
				description: `Are you sure you want to delete the entity ${revenueInvoiceName} ?`
			}
		})
	);
	const { payload } = yield take(closeModal.type);
	if (!payload.isConfirmed) {
		return;
	}

	yield put(deleteRevenueInvoice({ id }));
	yield race({
		done: take(deleteRevenueInvoiceAsync.done),
		failed: take(deleteRevenueInvoiceAsync.failed)
	});
}

function* watcher(): SagaIterator {
	yield takeLatest(deleteRevenueInvoiceModal, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
