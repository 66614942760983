import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	InterdepartmentalServicesState,
	initialState
} from '../interdepartmentalServicesState';
import {
	RetrieveInterdepartmentalServiceRequest,
	RetrieveInterdepartmentalServiceResponse
} from 'services/api/interdepartmentalService/interdepartmentalServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export const onRetrieveInterdepartmentalServicesSuccess = (
	state: InterdepartmentalServicesState,
	action: Success<
		RetrieveInterdepartmentalServiceRequest,
		RetrieveInterdepartmentalServiceResponse
	>
): InterdepartmentalServicesState => {
	const {
		result: { elements }
	} = action;
	return {
		...state,
		hasMore: elements.length === DEFAULT_LIST_LIMIT,
		data: [...state.data, ...elements],
		fetchStatuses: { ...state.fetchStatuses, retrieve: FetchStatus.SUCCESS }
	};
};

export const onRetrieveInterdepartmentalServicesFailed = (
	state: InterdepartmentalServicesState
): InterdepartmentalServicesState => ({
	...state,
	hasMore: false
});

export const onRetrieveInterdepartmentalServices = makeFetchStatusReducers<
	InterdepartmentalServicesState,
	'fetchStatuses',
	RetrieveInterdepartmentalServiceRequest,
	RetrieveInterdepartmentalServiceResponse
>('fetchStatuses', 'retrieve', {
	doneReducer: onRetrieveInterdepartmentalServicesSuccess,
	failedReducer: onRetrieveInterdepartmentalServicesFailed
});

export const onResetInterdepartmentalServices = (
	_state: InterdepartmentalServicesState
) => initialState;
