import actionCreator from '../interdepartmentalServiceActionCreator';
import {
	RetrieveInterdepartmentalServiceRequest,
	RetrieveInterdepartmentalServiceResponse
} from 'services/api/interdepartmentalService/interdepartmentalServiceTypes';

const ACTION_NAME = 'RETRIEVE_INTERDEPARTMENTAL_SERVICE';

export const retrieveInterdepartmentalServicesAsync = actionCreator.async<
	RetrieveInterdepartmentalServiceRequest,
	RetrieveInterdepartmentalServiceResponse,
	Error
>(ACTION_NAME);

export const retrieveInterdepartmentalServices = actionCreator<
	RetrieveInterdepartmentalServiceRequest | undefined
>(ACTION_NAME);

export const resetInterdepartmentalServices = actionCreator(
	'RESET_INTERDEPARTMENTAL_SERVICE'
);
