import client from 'services/client';
import {
	CreateInterdepartmentalServiceRequest,
	CreateInterdepartmentalServiceResponse,
	DeleteInterdepartmentalServiceRequest,
	DeleteInterdepartmentalServiceResponse,
	InterdepartmentalService,
	InterdepartmentalServiceExistsRequest,
	RetrieveCostCenterByOperatingEntityRequest,
	RetrieveCostCenterByOperatingEntityResponse,
	RetrieveInterdepartmentalServiceRequest,
	RetrieveInterdepartmentalServiceResponse,
	RetrieveOperatingEntitiesResponse,
	RetrieveProductStreamRequest,
	RetrieveProductStreamResponse,
	RetrieveServicesRequest,
	RetrieveServicesResponse,
	UpdateInterdepartmentalServiceRequest
} from './interdepartmentalServiceTypes';
import { AxiosPromise } from 'axios';

const retrieveInterdepartmentalService = ({
	index,
	limit
}: RetrieveInterdepartmentalServiceRequest): AxiosPromise<RetrieveInterdepartmentalServiceResponse> =>
	client.get(
		`/masterdata/interdepartment-services?Index=${index}&Limit=${limit}`
	);

const retrieveCostCenterByOperatingEntity = ({
	operatingEntityCode,
	searchTerm
}: RetrieveCostCenterByOperatingEntityRequest): AxiosPromise<RetrieveCostCenterByOperatingEntityResponse> =>
	client.get(
		`/masterdata/cost-centres/search?OperatingEntityCode=${operatingEntityCode}&SearchTerm=${searchTerm}`
	);

const retrieveProductStream = ({
	searchTerm
}: RetrieveProductStreamRequest): AxiosPromise<RetrieveProductStreamResponse> =>
	client.get(`/masterdata/product-streams/search?SearchTerm=${searchTerm}`);

const retrieveServices = ({
	searchTerm
}: RetrieveServicesRequest): AxiosPromise<RetrieveServicesResponse> =>
	client.get(`/masterdata/services/search?SearchTerm=${searchTerm}`);

const interdepartmentalServiceExists = ({
	operatingEntityCode,
	costCenterId,
	productStreamId,
	serviceCode
}: InterdepartmentalServiceExistsRequest): AxiosPromise<{}> =>
	client.get(
		`/masterdata/interdepartment-services/exists?OperatingEntityCode=${operatingEntityCode}&CostCenterId=${costCenterId}&ServiceCode=${serviceCode}&ProductStreamId=${productStreamId}`
	);

const searchOperatingEntities = (
	searchTerm: string
): AxiosPromise<RetrieveOperatingEntitiesResponse> =>
	client.get(`masterdata/operating-entities/search?searchTerm=${searchTerm}`);

const createInterdepartmentalService = (
	data: CreateInterdepartmentalServiceRequest
): AxiosPromise<CreateInterdepartmentalServiceResponse> =>
	client.post('/masterdata/interdepartment-services', data);

const updateInterdepartmentalService = ({
	id,
	operatingEntityCode,
	serviceCode,
	costCenterId,
	productStreamId
}: UpdateInterdepartmentalServiceRequest): AxiosPromise<InterdepartmentalService> =>
	client.put(`/masterdata/interdepartment-services/${id}`, {
		operatingEntityCode,
		serviceCode,
		costCenterId,
		productStreamId
	});

const deleteInterdepartmentalService = ({
	id
}: DeleteInterdepartmentalServiceRequest): AxiosPromise<DeleteInterdepartmentalServiceResponse> =>
	client.delete(`/masterdata/interdepartment-services/${id}`);

export default {
	retrieveInterdepartmentalService,
	searchOperatingEntities,
	retrieveProductStream,
	retrieveServices,
	retrieveCostCenterByOperatingEntity,
	interdepartmentalServiceExists,
	createInterdepartmentalService,
	updateInterdepartmentalService,
	deleteInterdepartmentalService
};
