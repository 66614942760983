import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import {
	GetAllRevenueInvoicesRequest,
	GetAllRevenueInvoicesResponse
} from 'services/api/revenueInvoice/revenueInvoiceServiceTypes';
import { retrieveRevenueInvoicesAsync } from '../actions';

export default makeTakeLatestWatcher<
	GetAllRevenueInvoicesRequest,
	GetAllRevenueInvoicesResponse,
	Error
>({
	api: Api.RevenueInvoice.getAllRevenueInvoices,
	async: retrieveRevenueInvoicesAsync
});
