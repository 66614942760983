import { keyBy, get, isNil, find, findIndex, isBoolean, every } from 'lodash';
import { AppState } from 'store-types.d';
import { createSelector } from 'reselect';
import {
	JobClassificationFiltersByGroupType,
	JobStatusByFinanceStatusAndUserRole,
	mapStatusToLabel,
	PORT_JOB_STATUS_STATE_MACHINE
} from './constants';
import {
	AppointDataService,
	PortJobOperation,
	PortJob,
	AppointData,
	AcceptanceData,
	PortJobMetadataActionCode,
	PortJobStatusComparisonType,
	PortJobCargoLineBase,
	PortJobOperationsWithCargoes
} from 'services/api/portJobs/portJobsServiceTypes';
import { ClassificationCode } from './../../services/api/portJobs/portJobsServiceTypes';
import { FinanceStatus } from 'services/api/finance/financeServiceTypes';
import {
	getPortCallData,
	getPortCallsState
} from 'store/portcalls/portCallsSelectors';
import { FetchStatus } from 'services/api/apiTypes';
import { getUserGroupType } from 'store/auth/selectors/user';
import { UserType } from 'services/api/users/userServiceTypes';
import { PortJobStatus } from 'store/portJobs/constants';
import { getIsLoading } from 'store/selectors';
import { getEntityMetadataAction } from 'store/metadata/utils/metadataUtils';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { getLinkedAppointmentJob } from 'store/linkJob/selectors/linkJobSelectors';
import { MetadataStatusCode } from 'app-constants';

const getPortJobMap = (state: AppState) => state.portjob.byId;

export const getPortJobMapByCode = (state: AppState) => {
	const portJobs = Object.values(getPortJobMap(state));
	return keyBy(portJobs, job => job.code);
};

export const getPortJobById = (state: AppState, id: string): PortJob =>
	getPortJobMap(state)[id];

export const getPortJobByCode = (state: AppState, jobCode: string): PortJob =>
	getPortJobMapByCode(state)[jobCode];

export const getPortJobConcurrencyTokenByCode = createSelector(
	getPortJobByCode,
	portJob => portJob?.concurrencyToken
);

export const getEditableOperation = (state: AppState) =>
	state.portjob.editableOperation;

export const getPortCallJobByCode = createSelector(
	getPortJobMap,
	(_state: AppState, code: string) => code,
	(portJobs, code) => find(portJobs, job => job.code === code)
);

export const getJobClassificationFilterForUserType = createSelector(
	getUserGroupType,
	groupType => {
		return JobClassificationFiltersByGroupType[groupType];
	}
);

export const getActivePortJobCode = (state: AppState): string =>
	state.portjob.context.activePortJobCode;

export const getIsPortJobDataUpToDate = (state: AppState) =>
	state.portjob.context.isJobDataUpToDate;

export const getSelectedLinkJob = (state: AppState) =>
	state.portjob.context.selectedLinkJobCode;

export const getSelectedLinkJobPortCallId = (state: AppState) =>
	state.portjob.context.selectedLinkJobPortCallId;

export const getIsUnlinkNotificationShow = (state: AppState) =>
	state.portjob.context.isUnlinkNotificationToShow;

export const getIsAgentInvited = (state: AppState) =>
	state.portjob.context.isAgentInvited;

export const getActivePortJob = createSelector(
	getActivePortJobCode,
	getPortJobMapByCode,
	(code, byCode): PortJob => byCode[code]
);

export const getLinkedJobDetailsFromActiveJob = createSelector(
	getActivePortJob,
	portJobState =>
		portJobState?.linkedPortJobDetails.find(linkedJob => linkedJob.portJobCode)
);

export const getLinkedJobPortCallIdFromActiveJob = createSelector(
	getLinkedJobDetailsFromActiveJob,
	linkedjobstate => linkedjobstate?.portCallId
);

export const getLinkedJobCodeFromActiveJob = createSelector(
	getLinkedJobDetailsFromActiveJob,
	linkedJobState => linkedJobState?.portJobCode
);

export const getActivePortJobCompanies = createSelector(getActivePortJob, job =>
	job ? job.portJobCompanies : []
);

export const getActivePortJobParentPortCallId = createSelector(
	getActivePortJob,
	job => (job?.parentPortCallId ? job.parentPortCallId : null)
);

export const getEmailSubject = createSelector(
	getActivePortJob,
	getPortCallData,
	(portJob, portCall) => {
		if (isNil(portCall) || !portJob) {
			return '';
		}
		return `Assistance request for Job ${portJob.code}, ${portCall.port.name}, ${portCall.vessel.name}`;
	}
);

export const getIsActivePortJobCancelled = createSelector(
	getActivePortJob,
	activePortJob => Boolean(activePortJob?.isCancelled)
);

// PORT JOB SUMMARY SELECTORS
export const isPortJobSummaryFetching = (state: AppState) =>
	state.portjob.fetchStatuses.summary === FetchStatus.PENDING;

export interface PortJobSummary {
	vesselName: string;
	portName: string;
	etaPlt: string;
	etsPlt?: string;
	appointerLabel: string;
	jobTypeName: string;
	performingAgentLabel: string;
}

const getCurrentPortJob = (state: AppState): PortJob => {
	const currentJobCode = getActivePortJobCode(state);
	const matchingJobs = Object.keys(state.portjob.byId)
		.map(id => state.portjob.byId[id])
		.filter(job => job.code === currentJobCode);

	return matchingJobs[0];
};

export const getCurrentPortJobOperations = createSelector(
	getCurrentPortJob,
	(job: PortJob): PortJobOperation[] => (job ? job.operations : [])
);

export const getCurrentMainPrincipalId = createSelector(
	getCurrentPortJob,
	(job: PortJob): string | null =>
		job?.hubPrincipalCompany ? job.hubPrincipalCompany.id : null
);

export const hasPortJobOperationRecordedEvents = (
	state: AppState,
	portJobOperationId: string
): boolean => {
	const currentJob = getCurrentPortJob(state);
	const operations = currentJob?.operations;
	const operation =
		operations?.length &&
		operations.find(op => op.portJobOperationId === portJobOperationId);
	if (!operation || isNil(operation.hasRecordedEvents)) {
		return false;
	}
	return operation.hasRecordedEvents;
};

export const getPortJobFullSummary = (state: AppState) =>
	state.portjob.context.summary;

export const getPortJobSummary = (state: AppState): PortJobSummary | null => {
	const summary = state.portjob.context.summary;
	if (!summary) {
		return null;
	}

	return {
		vesselName: summary.portCallSummary.vessel.name,
		portName: summary.portCallSummary.port.name,
		etaPlt: summary.portCallSummary.eta,
		etsPlt: summary.portCallSummary.ets || undefined,
		appointerLabel:
			summary.appointerCompany === undefined
				? ''
				: summary.appointerCompany.name,
		jobTypeName: summary.jobType,
		performingAgentLabel:
			summary.performingAgentCompany === undefined
				? ''
				: summary.performingAgentCompany.name
	};
};

export const getPortIdSummary = (state: AppState): string | null => {
	const summary = state.portjob.context.summary;
	if (!summary) {
		return null;
	}

	return summary.portCallSummary.port.id;
};

export const isPortJobUpdating = (state: AppState): boolean =>
	state.portjob.fetchStatuses.updating === FetchStatus.PENDING;

export const isPortJobOperationFetching = (state: AppState): boolean =>
	state.portjob.fetchStatuses.operation === FetchStatus.PENDING;

export const getIsPortJobLoading = (state: AppState) =>
	getIsLoading(state.portjob.fetchStatuses.all);

const getQueriedStatusLabel = (
	userType: UserType,
	portJobStatus: string,
	queriedToHub?: boolean,
	queriedToLPA?: boolean
) => {
	switch (userType) {
		case UserType.HUB:
			if (queriedToHub && !queriedToLPA) {
				return `${portJobStatus} queried (Hub)`;
			}
			if (!queriedToHub && queriedToLPA) {
				return `${portJobStatus} queried (LPA)`;
			}
			if (queriedToHub && queriedToLPA) {
				return `${portJobStatus} queried (Hub and LPA)`;
			}
			break;
		case UserType.PRINCIPAL:
			if (!queriedToHub && queriedToLPA) {
				return `${portJobStatus} verification pending`;
			}
			return `${portJobStatus} queried`;

		case UserType.LPA:
			if (queriedToHub && !queriedToLPA) {
				return `${portJobStatus} approval pending`;
			}
			return `${portJobStatus} queried`;

		default:
			return '';
	}

	return '';
};

export const lookupPortJobStatusLabel = (
	portJobStatus: string,
	financeStatus: FinanceStatus,
	userType: UserType,
	queriedToHub = false,
	queriedToLPA = false,
	isPortJobCancelled = false
): string => {
	if (isPortJobCancelled) {
		return 'Cancelled';
	}
	const needUsingOfFinanceStatus =
		financeStatus && portJobStatus
			? JobStatusByFinanceStatusAndUserRole[portJobStatus][financeStatus]
			: '';
	const propsToGetJobStatusText = needUsingOfFinanceStatus
		? [portJobStatus, financeStatus, userType]
		: [portJobStatus, userType];

	if (
		financeStatus === FinanceStatus.QUERIED &&
		portJobStatus !== PortJobStatus.EXECUTION &&
		portJobStatus !== PortJobStatus.COMPLETE
	) {
		return getQueriedStatusLabel(
			userType,
			portJobStatus,
			queriedToHub,
			queriedToLPA
		);
	}

	return (
		get(
			JobStatusByFinanceStatusAndUserRole,
			propsToGetJobStatusText,
			mapStatusToLabel(userType, portJobStatus)
		) || portJobStatus
	);
};

export const getActivePortJobStatus = createSelector(
	getActivePortJob,
	portJob => (portJob ? portJob.status : null)
);

export const getActiveJobStatusTimeComparisonSelector = (
	statusToCompare: PortJobStatus,
	comparisonType: PortJobStatusComparisonType = PortJobStatusComparisonType.SAME_OR_BEFORE
) =>
	createSelector(getActivePortJobStatus, activeStatus => {
		const activeStatusIdx = findIndex(
			PORT_JOB_STATUS_STATE_MACHINE,
			status => status === activeStatus
		);
		const statusToCompareIdx = findIndex(
			PORT_JOB_STATUS_STATE_MACHINE,
			status => status === statusToCompare
		);
		if (comparisonType === PortJobStatusComparisonType.SAME_OR_AFTER) {
			return activeStatusIdx >= statusToCompareIdx;
		}
		return activeStatusIdx <= statusToCompareIdx;
	});

export const getJobStatusComparisonSelector = (
	currentStatus: string,
	statusToCompare: string,
	comparisonType: PortJobStatusComparisonType = PortJobStatusComparisonType.SAME_OR_BEFORE
) => {
	const currentStatusIdx = findIndex(
		PORT_JOB_STATUS_STATE_MACHINE,
		status => status === currentStatus
	);
	const statusToCompareIdx = findIndex(
		PORT_JOB_STATUS_STATE_MACHINE,
		status => status === statusToCompare
	);
	if (comparisonType === PortJobStatusComparisonType.SAME_OR_AFTER) {
		return currentStatusIdx >= statusToCompareIdx;
	}
	return currentStatusIdx <= statusToCompareIdx;
};

export const getIsUpdatePortJobPossible = createSelector(
	getActivePortJobStatus,
	getIsActivePortJobCancelled,
	getActiveJobStatusTimeComparisonSelector(
		PortJobStatus.LPA_SETTLEMENTS,
		PortJobStatusComparisonType.SAME_OR_AFTER
	),
	getActiveJobStatusTimeComparisonSelector(
		PortJobStatus.DA_SETTLEMENTS,
		PortJobStatusComparisonType.SAME_OR_AFTER
	),
	(
		activePortJobStatus,
		isCancelled,
		isSettlementStatusOrLater,
		isDaSettlementStatusOrLater
	) =>
		activePortJobStatus === null ||
		(!isCancelled && !isSettlementStatusOrLater && !isDaSettlementStatusOrLater)
);

export const getIsStatusFurtherWaitAppointWaitInvite = createSelector(
	getActiveJobStatusTimeComparisonSelector(
		PortJobStatus.AWAITING_APPOINTMENT,
		PortJobStatusComparisonType.SAME_OR_BEFORE
	),
	getActiveJobStatusTimeComparisonSelector(
		PortJobStatus.AWAITING_INVITATION,
		PortJobStatusComparisonType.SAME_OR_BEFORE
	),
	(isStatusLaterWaitAppoint, isStatusLaterWaitInvite) =>
		isStatusLaterWaitAppoint || isStatusLaterWaitInvite
);

export const getIsAgentFieldDisabled = createSelector(
	getActiveJobStatusTimeComparisonSelector(
		PortJobStatus.APPOINTED,
		PortJobStatusComparisonType.SAME_OR_AFTER
	),
	isStatusAppointedOrLater => isStatusAppointedOrLater
);

export const getIsAgentFieldEditable = createSelector(
	getActiveJobStatusTimeComparisonSelector(PortJobStatus.APPOINTED),
	isStatusAppointedOrBefore => isStatusAppointedOrBefore
);

export const getCancelPortJobAction = createSelector(
	getActivePortJob,
	(portJob: PortJob) => {
		const metadata = portJob.metadata;
		if (!metadata) {
			return undefined;
		}
		return getEntityMetadataAction<PortJobMetadataActionCode>(
			metadata.actions,
			PortJobMetadataActionCode.CANCEL
		);
	}
);

export const getIsChangeCustodyRolePossible = createSelector(
	getActivePortJobStatus,
	getIsActivePortJobCancelled,
	getActiveJobStatusTimeComparisonSelector(
		PortJobStatus.APPOINTED,
		PortJobStatusComparisonType.SAME_OR_AFTER
	),
	(activePortJobStatus, isCancelled, isStatusAfterConfirmed) => {
		return (
			Boolean(activePortJobStatus) && !isCancelled && isStatusAfterConfirmed
		);
	}
);

export const getIsStatusChangePossible = createSelector(
	getActivePortJobStatus,
	getIsActivePortJobCancelled,
	getActiveJobStatusTimeComparisonSelector(
		PortJobStatus.CONFIRMED,
		PortJobStatusComparisonType.SAME_OR_AFTER
	),
	(activePortJobStatus, isCancelled, isStatusAfterConfirmed) => {
		return (
			Boolean(activePortJobStatus) && !isCancelled && isStatusAfterConfirmed
		);
	}
);

export const getActiveJobType = createSelector(
	getActivePortJob,
	portJob => portJob.jobType
);

export const getCanChangeStatus = createSelector(
	getActivePortJobStatus,
	portJobStatus =>
		portJobStatus === PortJobStatus.AWAITING_APPOINTMENT ||
		portJobStatus === PortJobStatus.AWAITING_INVITATION
);

const PortJobStatuses = {
	[PortJobStatus.CREATED]: 'Created',
	[PortJobStatus.AWAITING_APPOINTMENT]: 'WaitAppont',
	[PortJobStatus.AWAITING_ACCEPTANCE]: 'WaitAccept',
	[PortJobStatus.AWAITING_INVITATION]: 'WaitInvite',
	[PortJobStatus.APPOINTED]: 'Appointed',
	[PortJobStatus.CONFIRMED]: 'Confirmed'
};

export const getSendCancelEmailJobStatus = createSelector(
	getActivePortJob,
	portjob => portjob?.status === PortJobStatuses[portjob?.status]
);

export const getTogglePortJobStatus = createSelector(
	getActivePortJobStatus,
	portJobStatus =>
		portJobStatus === PortJobStatus.AWAITING_INVITATION
			? PortJobStatus.AWAITING_APPOINTMENT
			: PortJobStatus.AWAITING_INVITATION
);

export const getIsMovetoPDAPending = createSelector(
	getActivePortJobStatus,
	getIsActivePortJobCancelled,
	(activePortJobStatus, isCancelled) => {
		return activePortJobStatus === PortJobStatus.CE && !isCancelled;
	}
);

export const getMovetoCEPendingAction = createSelector(
	getActivePortJob,
	(portJob: PortJob) => {
		const metadata = portJob.metadata;
		if (!metadata) {
			return undefined;
		}
		return getEntityMetadataAction<PortJobMetadataActionCode>(
			metadata.actions,
			PortJobMetadataActionCode.MOVE_TO_CE_PENDING
		);
	}
);

export const getIsMovetoCEPending = createSelector(
	getIsActivePortJobCancelled,
	getMovetoCEPendingAction,
	(isCancelled, onceDAApproved) => {
		return (
			onceDAApproved?.statusCode === MetadataStatusCode.ENABLED && !isCancelled
		);
	}
);

export const getReOpenJobAction = createSelector(
	getActivePortJob,
	(portJob: PortJob) => {
		const metadata = portJob.metadata;
		if (!metadata) {
			return undefined;
		}
		return getEntityMetadataAction<PortJobMetadataActionCode>(
			metadata.actions,
			PortJobMetadataActionCode.RE_OPEN_JOB
		);
	}
);

export const getIsReOpenJob = createSelector(getReOpenJobAction, reOpenJob => {
	return reOpenJob?.statusCode === MetadataStatusCode.ENABLED;
});

/**
 * Port Job Context
 */
const getPortJobContext = (state: AppState, key: string) =>
	state.portjob.context[key];

export const getIsMainPrincipalTypeCLS = (state: AppState) =>
	getPortJobContext(state, 'isMainPrincipalTypeCLS');

/* Appoint Data */

const getPortJobAppointData = (state: AppState) => state.portjob.appointData;

export const getPortJobAppointDataFetchStatus = (state: AppState) =>
	state.portjob.fetchStatuses.appointData;

export const getPortJobServiceVendors = createSelector(
	getPortJobAppointData,
	(appointData: AppointData) => appointData.serviceVendors
);

// selectors filters services for only those with 2 or more available vendors
export const getPortJobServiceVendorsWithManyAvailableVendors = createSelector(
	getPortJobServiceVendors,
	(services: AppointDataService[]) =>
		services.filter(service => service.availableVendors.length > 1)
);

/* Extended Messaging Data */

export const getPortJobExtendedMessagingFetchStatus = (state: AppState) =>
	state.portjob.fetchStatuses.extendedMessagingData;

export const getExtendedMessages = (state: AppState) =>
	state.portjob.extendedMessagingData.messages;

export const getExtendedMessageData = (state: AppState) =>
	state.portjob.extendedMessagingData;

export const isExtendedMessageSending = (state: AppState): boolean =>
	state.portjob.fetchStatuses.sendExtendedMessages === FetchStatus.PENDING;

export const getSentThreadId = (state: AppState) =>
	state.portjob.context.lastSentThreadId;

/* Acceptance data */

const getPortJobAcceptanceData = (state: AppState) =>
	state.portjob.acceptanceData;

export const getPortJobAcceptanceDataFetchStatus = (state: AppState) =>
	state.portjob.fetchStatuses.acceptanceData;

export const getPortJobAcceptanceVendors = createSelector(
	getPortJobAcceptanceData,
	(acceptanceData: AcceptanceData) => acceptanceData.vendors
);

export const getPortJobAcceptanceAttachments = createSelector(
	getPortJobAcceptanceData,
	(acceptanceData: AcceptanceData) => acceptanceData.appointmentAttachments
);

export const getPortJobAcceptanceInstruction = createSelector(
	getPortJobAcceptanceData,
	(acceptanceData: AcceptanceData) =>
		acceptanceData.appointmentAcceptanceInstruction
);

export const getIsPortJobAcceptanceDataEmpty = createSelector(
	getPortJobAcceptanceVendors,
	getPortJobAcceptanceAttachments,
	getPortJobAcceptanceInstruction,
	(vendors, attachments, instructions) =>
		vendors.length === 0 && attachments.length === 0 && !instructions
);

const getPortJobOperation = createSelector(
	getPortJobByCode,
	(_state: AppState, _jobCode: string, opeationId: string) => opeationId,
	(portJob, operationId) =>
		portJob?.operations.find(
			operation => operation.portJobOperationId === operationId
		)
);

export const getPortJobOperationConcurrencyToken = createSelector(
	getPortJobOperation,
	operation => operation?.concurrencyToken
);

export const getIsPortJobOperationUpToDate = (state: AppState) =>
	state.portjob.context.isPortJobOperationUpToDate;

export const getPortJobsMetadata = (state: AppState) => state.portjob.metadata;

export const getIsAnyPortJobHavingFinanceAvailable = (state: AppState) =>
	Object.values(getPortJobMap(state)).some(
		portJob =>
			portJob.status !== PortJobStatus.CREATED &&
			portJob.status !== PortJobStatus.CONFIRMED &&
			portJob.status !== PortJobStatus.APPOINTED &&
			!portJob.isCancelled
	);

export const getIsActivePortJobCLS = createSelector(
	getActivePortJob,
	job => job?.classification.code === ClassificationCode.C
);

export const getPortJobWizardFormInitialized = createSelector(
	getIsMainPrincipalTypeCLS,
	isMainPrincipalTypeCLS => isBoolean(isMainPrincipalTypeCLS)
);

export const getIsJobLinked = createSelector(getActivePortJob, job =>
	job?.linkedJobs.length > 0 ? true : false
);

export const getIsAgentsSwapPossible = createSelector(getActivePortJob, job =>
	job?.isAgentsSwapDisabled ? true : false
);

export const getIsLeadNominationJob = createSelector(
	getActivePortJob,
	job => job?.isMutipleLinkedLeadNominationJobs
);

export const getLinkedAppointerIsNonIssHubPrincipal = createSelector(
	getActivePortJob,
	job => job?.isAppointerNonIssHubPrincipal
);

export const getLinkedAppJobCodes = createSelector(
	getActivePortJob,
	job => job.linkedJobs
);

export const getIsOperationsDisabled = createSelector(
	getActivePortJob,
	job => job?.isOperationsDisabled
);

export const getIsAppointer = createSelector(getActivePortJob, job =>
	job?.appointerCompany ? true : false
);

export const getIsDraftAppointer = createSelector(
	getActivePortJob,
	job => job?.isDraftAppointer
);

export const getIsLinkCargoProcess = (state: AppState) =>
	state.portjob.context.isForLinkCargoProcess;

export const getSelectedLinkedAppointmentJob = (state: AppState) => {
	const isLinkCargo = getIsLinkCargoProcess(state);
	if (isLinkCargo) {
		return getLinkedAppointmentJob(state);
	}
	const duplicatedPortCalls = getPortCallsState(state);
	const linkedJob = getSelectedLinkJob(state);
	const linkedPortCall = duplicatedPortCalls.duplicates.all.find(portCall =>
		portCall.jobs.find(job => job.code === linkedJob)
	);
	return linkedPortCall?.jobs.find(job => job.code === linkedJob);
};

export const isCargoLinkButtonActive = createSelector(
	getCurrentPortJobOperations,
	(operations: PortJobOperationsWithCargoes[]) => {
		let cargoes: PortJobCargoLineBase[] = [];
		operations.forEach(operation => {
			if (
				(operation.code === OperationTypeCode.LOADING ||
					operation.code === OperationTypeCode.DISCHARGING ||
					operation.code === OperationTypeCode.LOADING_STS ||
					operation.code === OperationTypeCode.DISCHARGING_STS) &&
				operation.cargoes
			) {
				cargoes = [...cargoes, ...operation.cargoes];
			}
		});

		return every(cargoes, 'isLinkedCargo');
	}
);
