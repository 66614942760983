import {
	Entity,
	UpdateEntityRequest
} from 'services/api/entities/entitiesServiceTypes';
import Api from 'services/api';
import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { notify } from 'store/notifications/actions';
import { AxiosResponse } from 'axios';
import { updateEntity, updateEntityAsync } from '../actions/updateEntity';

export function* worker({
	payload
}: Action<UpdateEntityRequest>): SagaIterator {
	yield put(updateEntityAsync.started(payload));

	try {
		const response: AxiosResponse<Entity> = yield call(
			Api.Entities.updateEntity,
			payload
		);

		if (response.status === 200) {
			yield put(
				notify.success(
					`${response.data.name} Operating Entity has been successfully updated.`
				)
			);

			yield put(
				updateEntityAsync.done({
					result: response.data,
					params: payload,
					response
				})
			);
		}
	} catch (error) {
		yield put(
			updateEntityAsync.failed({
				error,
				params: payload
			})
		);
	}
}

function* watcher(): SagaIterator {
	yield takeLatest(updateEntity, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
