import client from 'services/client';
import { AxiosPromise } from 'axios';
import {
	CreateRevenueInvoiceRequest,
	CreateRevenueInvoiceResponse,
	DeleteRevenueInvoiceRequest,
	DeleteRevenueInvoiceResponse,
	GetAllRevenueInvoicesRequest,
	GetAllRevenueInvoicesResponse,
	GetRevenueInvoiceResponse,
	GetRevenueInvoiceTitlesResponse,
	RetrieveRevenueInvoiceSearchResponse,
	UpdateRevenueInvoiceRequest,
	UpdateRevenueInvoiceResponse
} from './revenueInvoiceServiceTypes';

const getAllRevenueInvoices = ({
	index,
	sortBy,
	limit
}: GetAllRevenueInvoicesRequest): AxiosPromise<GetAllRevenueInvoicesResponse> =>
	client.get('/masterdata/templates/revenue-invoice-titles', {
		params: {
			sortBy,
			limit,
			index
		}
	});

const getRevenueInvoice = (
	id: string
): AxiosPromise<GetRevenueInvoiceResponse> =>
	client.get(`/masterdata/templates/revenue-invoice-titles/${id}`);

const getRevenueInvoiceTitles = (): AxiosPromise<GetRevenueInvoiceTitlesResponse> =>
	client.get(`/masterdata/templates/revenue-invoice-titles/titles`);

const searchRevenueInvoices = (
	searchTerm: string
): AxiosPromise<RetrieveRevenueInvoiceSearchResponse> =>
	client.get(
		`/masterdata/templates/revenue-invoice-titles/organization-entities/search?searchTerm=${searchTerm}`
	);

const createRevenueInvoice = (
	data: CreateRevenueInvoiceRequest
): AxiosPromise<CreateRevenueInvoiceResponse> =>
	client.post(`/masterdata/templates/revenue-invoice-titles`, data);

const updateRevenueInvoice = ({
	id,
	title
}: UpdateRevenueInvoiceRequest): AxiosPromise<UpdateRevenueInvoiceResponse> =>
	client.put(`/masterdata/templates/revenue-invoice-titles/${id}`, {
		title
	});

const deleteRevenueInvoice = ({
	id
}: DeleteRevenueInvoiceRequest): AxiosPromise<DeleteRevenueInvoiceResponse> =>
	client.delete(`/masterdata/templates/revenue-invoice-titles/${id}`);

export default {
	getAllRevenueInvoices,
	getRevenueInvoice,
	getRevenueInvoiceTitles,
	searchRevenueInvoices,
	createRevenueInvoice,
	updateRevenueInvoice,
	deleteRevenueInvoice
};
