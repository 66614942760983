import {
	CreateEntityRequest,
	CreateEntityResponse
} from 'services/api/entities/entitiesServiceTypes';
import Api from 'services/api';
import { createEntity, createEntityAsync } from '../actions';
import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { notify } from 'store/notifications/actions';
import { AxiosResponse } from 'axios';

export function* worker({
	payload
}: Action<CreateEntityRequest>): SagaIterator {
	yield put(createEntityAsync.started(payload));

	try {
		const response: AxiosResponse<CreateEntityResponse> = yield call(
			Api.Entities.createEntity,
			payload
		);

		if (response.status === 201) {
			yield put(
				notify.success('A new Operating Entity has been successfully created.')
			);
		}

		yield put(
			createEntityAsync.done({
				result: response.data,
				params: payload,
				response
			})
		);
	} catch (error) {
		yield put(
			createEntityAsync.failed({
				error,
				params: payload
			})
		);
	}
}

function* watcher(): SagaIterator {
	yield takeLatest(createEntity, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
