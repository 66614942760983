import {
	CreateCostCentrePayload,
	CreateCostCentreResponse
} from 'services/api/costCentre/costCentreServiceTypes';
import actionCreator from '../costCentresActionCreator';

const ACTION_NAME = 'CREATE_COST_CENTRE';

export const createCostCentreAsync = actionCreator.async<
	CreateCostCentrePayload,
	CreateCostCentreResponse,
	Error
>(ACTION_NAME);

export const createCostCentre = actionCreator<CreateCostCentrePayload>(
	ACTION_NAME
);
