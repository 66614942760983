import {
	GetAllRevenueInvoicesRequest,
	GetAllRevenueInvoicesResponse
} from 'services/api/revenueInvoice/revenueInvoiceServiceTypes';
import actionCreator from '../revenueInvoiceActionCreator';

const ACTION_NAME = 'RETRIEVE_REVENUE_INVOICES';

export const retrieveRevenueInvoicesAsync = actionCreator.async<
	GetAllRevenueInvoicesRequest,
	GetAllRevenueInvoicesResponse,
	Error
>(ACTION_NAME);

export const retrieveRevenueInvoices = actionCreator<
	GetAllRevenueInvoicesRequest | undefined
>(ACTION_NAME);

export const resetRevenueInvoices = actionCreator('RESET_REVENUE_INVOICES');
