import { FetchStatus } from 'services/api/apiTypes';
import { Entity } from 'services/api/entities/entitiesServiceTypes';

export interface EntitiesState {
	hasMore: boolean;
	data: Array<Entity>;
	fetchStatuses: {
		retrieve: FetchStatus;
		delete: FetchStatus;
		create: FetchStatus;
		update: FetchStatus;
	};
}

export const initialState: EntitiesState = {
	hasMore: true,
	data: [],
	fetchStatuses: {
		retrieve: FetchStatus.IDLE,
		delete: FetchStatus.IDLE,
		create: FetchStatus.IDLE,
		update: FetchStatus.IDLE
	}
};
