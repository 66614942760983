import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

import Api from 'services/api';
import {
	RetrieveCostCentreRequest,
	RetrieveCostCentreResponse
} from 'services/api/costCentre/costCentreServiceTypes';
import { retrieveCostCentresAsync } from '../actions/retrieveCostCentres';
import { select } from 'redux-saga/effects';
import { getRetrieveCostCentresCount } from '../selectors';

export default makeTakeLatestWatcher<
	RetrieveCostCentreRequest,
	RetrieveCostCentreResponse,
	Error
>({
	api: Api.CostCentre.retrieveCostCentres,
	async: retrieveCostCentresAsync,
	*getApiParams(payload) {
		const count = yield select(getRetrieveCostCentresCount);
		const request: RetrieveCostCentreRequest = {
			index: count,
			...payload
		};
		return request;
	}
});
