import {
	put,
	select,
	takeLatest,
	all,
	fork,
	take,
	race
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
	deleteEntity,
	DeleteEntityActionParams,
	deleteEntityAsync,
	deleteEntityModal
} from '../actions';
import { getEntityById } from '../selectors';
import { Action } from 'typescript-fsa';
import { closeModal, openModal } from 'store/modals/actions';
import { Entity } from 'services/api/entities/entitiesServiceTypes';

export function* worker({
	payload: { id }
}: Action<DeleteEntityActionParams>): SagaIterator {
	const entity: Entity = yield select(getEntityById, id);
	const entityName = entity ? entity.name : '';

	yield put(
		openModal({
			name: 'deleteEntity',
			type: 'confirm',
			data: {
				description: `Are you sure you want to delete the entity ${entityName} ?`
			}
		})
	);
	const { payload } = yield take(closeModal.type);
	if (!payload.isConfirmed) {
		return;
	}

	yield put(deleteEntity({ id }));
	yield race({
		done: take(deleteEntityAsync.done),
		failed: take(deleteEntityAsync.failed)
	});
}

function* watcher(): SagaIterator {
	yield takeLatest(deleteEntityModal, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
