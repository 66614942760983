import {
	RetrieveInterdepartmentalServiceRequest,
	RetrieveInterdepartmentalServiceResponse
} from 'services/api/interdepartmentalService/interdepartmentalServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

import Api from 'services/api';
import { retrieveInterdepartmentalServicesAsync } from '../actions/retrieveInterdepartmentalServices';
import { select } from 'redux-saga/effects';
import { getRetrieveInterdepartmentalServiceCount } from '../selectors';

export default makeTakeLatestWatcher<
	RetrieveInterdepartmentalServiceRequest,
	RetrieveInterdepartmentalServiceResponse,
	Error
>({
	api: Api.InterdepartmentalServices.retrieveInterdepartmentalService,
	async: retrieveInterdepartmentalServicesAsync,
	*getApiParams(payload) {
		const count = yield select(getRetrieveInterdepartmentalServiceCount);
		const request: RetrieveInterdepartmentalServiceRequest = {
			index: count,
			...payload
		};
		return request;
	}
});
