import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { FetchStatus } from '../../../services/api/apiTypes';

const getRevenueInvoicesState = (state: AppState) => state.revenueInvoice;

export const getRevenueInvoicesData = (state: AppState) =>
	getRevenueInvoicesState(state).data;

export const getRetrieveRevenueInvoicesCount = createSelector(
	getRevenueInvoicesData,
	revenueInvoices => revenueInvoices.length
);

export const getRetrieveRevenueInvoicesIsFetching = (state: AppState) =>
	getRevenueInvoicesState(state).fetchStatuses.retrieve === FetchStatus.PENDING;

export const getCreateRevenueInvoiceIsFetching = (state: AppState) =>
	getRevenueInvoicesState(state).fetchStatuses.create === FetchStatus.PENDING;

export const getDeleteRevenueInvoiceIsFetching = (state: AppState) =>
	getRevenueInvoicesState(state).fetchStatuses.delete === FetchStatus.PENDING;

export const getUpdateRevenueInvoiceIsFetching = (state: AppState) =>
	getRevenueInvoicesState(state).fetchStatuses.update === FetchStatus.PENDING;

export const getRevenueInvoicesHasMore = (state: AppState) =>
	getRevenueInvoicesState(state).hasMore;

export const getRevenueInvoiceById = createSelector(
	getRevenueInvoicesData,
	(_state: AppState, id: string) => id,
	(revenueInvoices, id) =>
		revenueInvoices.find(revenueInvoice => revenueInvoice.id === id)
);
