import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { map } from 'lodash';
import {
	RevenueInvoice,
	UpdateRevenueInvoiceRequest
} from 'services/api/revenueInvoice/revenueInvoiceServiceTypes';
import { RevenueInvoiceState } from '../revenueInvoiceState';

export const onUpdateRevenueInvoice = makeFetchStatusReducers<
	RevenueInvoiceState,
	'fetchStatuses',
	UpdateRevenueInvoiceRequest,
	RevenueInvoice
>('fetchStatuses', 'update', {
	doneReducer: (state, { result }) => ({
		...state,
		data: map(state.data, (revenueInvoice: RevenueInvoice) =>
			revenueInvoice.id === result.id ? result : revenueInvoice
		)
	})
});
