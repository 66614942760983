import { FetchStatus } from 'services/api/apiTypes';
import { CostCentreFormData } from 'services/api/costCentre/costCentreServiceTypes';

export interface CostCentreState {
	hasMore: boolean;
	data: Array<CostCentreFormData>;
	fetchStatuses: {
		retrieve: FetchStatus;
		delete: FetchStatus;
		create: FetchStatus;
		update: FetchStatus;
	};
}

export const initialState: CostCentreState = {
	hasMore: true,
	data: [],
	fetchStatuses: {
		retrieve: FetchStatus.IDLE,
		delete: FetchStatus.IDLE,
		create: FetchStatus.IDLE,
		update: FetchStatus.IDLE
	}
};
