import {
	DeleteInterdepartmentalServiceRequest,
	DeleteInterdepartmentalServiceResponse
} from 'services/api/interdepartmentalService/interdepartmentalServiceTypes';
import actionCreator from '../interdepartmentalServiceActionCreator';

const ACTION_NAME = 'DELETE_INTERDEPARTMENTAL_SERVICE';

export const deleteInterdepartmentalServiceAsync = actionCreator.async<
	DeleteInterdepartmentalServiceRequest,
	DeleteInterdepartmentalServiceResponse,
	Error
>(ACTION_NAME);

export const deleteInterdepartmentalService = actionCreator<
	DeleteInterdepartmentalServiceRequest
>(ACTION_NAME);

export const deleteInterdepartmentalServiceModal = actionCreator<
	DeleteInterdepartmentalServiceRequest
>(`${ACTION_NAME}_MODAL`);
