import Api from 'services/api';
import { retrieveAvailableServicesAsync as asyncAction } from '../actions/retrieveAvailableServices';
import {
	RetrieveAvailableServicesRequest,
	RetrieveAvailableServicesResponse
} from 'services/api/finance/financeServiceTypes';

import { select } from 'redux-saga/effects';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

const api = Api.Finance.retrieveAvailableServices;

export default makeTakeEveryWatcher<
	RetrieveAvailableServicesRequest,
	RetrieveAvailableServicesResponse,
	Error
>({
	api,
	async: asyncAction,
	*getApiParams(params: RetrieveAvailableServicesRequest) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const request: RetrieveAvailableServicesRequest = {
			portCallId,
			jobCode,
			...params
		};
		return request;
	}
});
