import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { initialState } from './entitiesState';
import {
	resetEntities,
	retrieveEntitiesAsync
} from './actions/retrieveEntities';
import {
	onCreateEntity,
	onDeleteEntity,
	onResetEntities,
	onRetrieveEntities,
	onUpdateEntity
} from './reducers';
import {
	createEntityAsync,
	deleteEntityAsync,
	updateEntityAsync
} from './actions';

export default reducerWithInitialState(initialState)
	// Retrieve
	.case(retrieveEntitiesAsync.started, onRetrieveEntities.started)
	.case(retrieveEntitiesAsync.done, onRetrieveEntities.done)
	.case(retrieveEntitiesAsync.failed, onRetrieveEntities.failed)
	// Reset
	.case(resetEntities, onResetEntities)
	// Delete
	.case(deleteEntityAsync.started, onDeleteEntity.started)
	.case(deleteEntityAsync.done, onDeleteEntity.done)
	.case(deleteEntityAsync.failed, onDeleteEntity.failed)
	// Create
	.case(createEntityAsync.started, onCreateEntity.started)
	.case(createEntityAsync.done, onCreateEntity.done)
	.case(createEntityAsync.failed, onCreateEntity.failed)
	// Update
	.case(updateEntityAsync.started, onUpdateEntity.started)
	.case(updateEntityAsync.done, onUpdateEntity.done)
	.case(updateEntityAsync.failed, onUpdateEntity.failed);
