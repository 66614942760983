import actionCreator from '../interdepartmentalServiceActionCreator';
import {
	CreateInterdepartmentalServiceRequest,
	CreateInterdepartmentalServiceResponse
} from 'services/api/interdepartmentalService/interdepartmentalServiceTypes';

const ACTION_NAME = 'CREATE_INTERDEPARTMENTAL_SERVICE';

export const createInterdepartmentalServiceAsync = actionCreator.async<
	CreateInterdepartmentalServiceRequest,
	CreateInterdepartmentalServiceResponse,
	Error
>(ACTION_NAME);

export const createInterdepartmentalService = actionCreator<
	CreateInterdepartmentalServiceRequest
>(ACTION_NAME);
