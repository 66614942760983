import {
	all,
	fork,
	put,
	race,
	select,
	take,
	takeLatest
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Action } from 'typescript-fsa';
import {
	deleteCostCentre,
	DeleteCostCentreActionParams,
	deleteCostCentreAsync,
	deleteCostCentreModal
} from '../actions';
import { closeModal, openModal } from 'store/modals/actions';
import { CostCentre } from 'services/api/costCentre/costCentreServiceTypes';
import { getCostCentreById } from '../selectors';

export function* worker({
	payload: { id }
}: Action<DeleteCostCentreActionParams>): SagaIterator {
	const costCentre: CostCentre = yield select(getCostCentreById, id);
	const costCentreName = costCentre ? costCentre.operatingEntityName : '';

	yield put(
		openModal({
			name: 'deleteEntity',
			type: 'confirm',
			data: {
				description: `Are you sure you want to delete ${costCentreName} ?`
			}
		})
	);
	const { payload } = yield take(closeModal.type);
	if (!payload.isConfirmed) {
		return;
	}

	yield put(deleteCostCentre({ id }));
	yield race({
		done: take(deleteCostCentreAsync.done),
		failed: take(deleteCostCentreAsync.failed)
	});
}

function* watcher(): SagaIterator {
	yield takeLatest(deleteCostCentreModal, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
