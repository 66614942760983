import client from 'services/client';
import { AxiosPromise } from 'axios';
import { RetrieveMainPrincipalTypesResponse } from './mainPrincipalTypesServiceTypes';

const retrieveMainPrincipalTypes = (): AxiosPromise<RetrieveMainPrincipalTypesResponse> =>
	client.get(`masterdata/main-principal-types`);

export default {
	retrieveMainPrincipalTypes
};
