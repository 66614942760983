import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { InterdepartmentalServicesState } from '../interdepartmentalServicesState';
import {
	CreateInterdepartmentalServiceRequest,
	CreateInterdepartmentalServiceResponse
} from 'services/api/interdepartmentalService/interdepartmentalServiceTypes';

export const onCreateInterdepartmentalServices = makeFetchStatusReducers<
	InterdepartmentalServicesState,
	'fetchStatuses',
	CreateInterdepartmentalServiceRequest,
	CreateInterdepartmentalServiceResponse
>('fetchStatuses', 'create', {
	doneReducer: (state, { result }) => ({
		...state,
		data: [...state.data, result]
	})
});
