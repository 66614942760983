import {
	DeleteCostCentreRequest,
	DeleteCostCentreResponse
} from 'services/api/costCentre/costCentreServiceTypes';
import actionCreator from '../costCentresActionCreator';

const ACTION_NAME = 'DELETE_COST_CENTRE';

export interface DeleteCostCentreActionParams {
	id: string;
}

export const deleteCostCentreAsync = actionCreator.async<
	DeleteCostCentreRequest,
	DeleteCostCentreResponse,
	Error
>(ACTION_NAME);

export const deleteCostCentre = actionCreator<DeleteCostCentreActionParams>(
	ACTION_NAME
);

export const deleteCostCentreModal = actionCreator<
	DeleteCostCentreActionParams
>(`${ACTION_NAME}_MODAL`);
