import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { EntitiesState, initialState } from '../entitiesState';
import {
	RetrieveEntityRequest,
	RetrieveEntityResponse
} from 'services/api/entities/entitiesServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export const onRetrieveEntitiesSuccess = (
	state: EntitiesState,
	action: Success<RetrieveEntityRequest, RetrieveEntityResponse>
): EntitiesState => {
	const {
		result: { elements }
	} = action;
	return {
		...state,
		hasMore: elements.length === DEFAULT_LIST_LIMIT,
		data: elements,
		fetchStatuses: { ...state.fetchStatuses, retrieve: FetchStatus.SUCCESS }
	};
};

export const onRetrieveEntitiesFailed = (
	state: EntitiesState
): EntitiesState => ({
	...state,
	hasMore: false
});

export const onRetrieveEntities = makeFetchStatusReducers<
	EntitiesState,
	'fetchStatuses',
	RetrieveEntityRequest,
	RetrieveEntityResponse
>('fetchStatuses', 'retrieve', {
	doneReducer: onRetrieveEntitiesSuccess,
	failedReducer: onRetrieveEntitiesFailed
});

export const onResetEntities = (_state: EntitiesState) => initialState;
