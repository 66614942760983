import Api from 'services/api';
import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { notify } from 'store/notifications/actions';
import { AxiosResponse } from 'axios';
import {
	RevenueInvoice,
	UpdateRevenueInvoiceRequest
} from 'services/api/revenueInvoice/revenueInvoiceServiceTypes';
import { updateRevenueInvoice, updateRevenueInvoiceAsync } from '../actions';

export function* worker({
	payload
}: Action<UpdateRevenueInvoiceRequest>): SagaIterator {
	yield put(updateRevenueInvoiceAsync.started(payload));
	try {
		const response: AxiosResponse<RevenueInvoice> = yield call(
			Api.RevenueInvoice.updateRevenueInvoice,
			payload
		);

		if (response.status === 200) {
			yield put(
				notify.success(
					`${response.data.operatingEntityCode} Operating Entity has been successfully updated.`
				)
			);
			yield put(
				updateRevenueInvoiceAsync.done({
					result: response.data,
					params: payload,
					response
				})
			);
		}
	} catch (error) {
		yield put(
			updateRevenueInvoiceAsync.failed({
				error,
				params: payload
			})
		);
	}
}

function* watcher(): SagaIterator {
	yield takeLatest(updateRevenueInvoice, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
