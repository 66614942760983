import { all, fork } from 'redux-saga/effects';
import createInterdepartmentalServicesSaga from './createInterdepartmentalServiceSaga';
import retrieveInterdepartmentalServiceSaga from './retrieveInterdepartmentalServicesSaga';
import deleteInterdepartmentalServiceModalSaga from './deleteInterdepartmentalServiceModalSaga';
import deleteInterdepartmentalServiceSaga from './deleteInterdepartmentalServiceSaga';
import updateInterdepartmentalServiceSaga from './updateInterdepartmentalServiceSaga';

export default function*() {
	yield all([fork(retrieveInterdepartmentalServiceSaga)]);
	yield all([fork(deleteInterdepartmentalServiceModalSaga)]);
	yield all([fork(deleteInterdepartmentalServiceSaga)]);
	yield all([fork(createInterdepartmentalServicesSaga)]);
	yield all([fork(updateInterdepartmentalServiceSaga)]);
}
