import {
	CostCentre,
	CostCentreFormData
} from 'services/api/costCentre/costCentreServiceTypes';

export const formatCostCentreData = (
	elements: CostCentre[]
): CostCentreFormData[] =>
	elements.map(costElement => ({
		id: costElement.id,
		operatingEntityName: `${costElement.operatingEntityCode} - ${costElement.operatingEntityName}`,
		operatingEntityCode: costElement.operatingEntityCode,
		costCenterId: costElement.costCenterId,
		costCenterName: `${costElement.costCenterCode} - ${costElement.costCenterName}`,
		portId: costElement.portId ?? undefined,
		portName: costElement.portName
			? `${costElement.portName} (${costElement.portCode})`
			: undefined,
		companyId: costElement.companyId ?? undefined,
		companyName: costElement.companyName ?? undefined,
		mainPrincipalTypeCode: costElement.mainPrincipalTypeCode
	}));
