import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

import {
	CreateRevenueInvoiceRequest,
	CreateRevenueInvoiceResponse
} from 'services/api/revenueInvoice/revenueInvoiceServiceTypes';
import { RevenueInvoiceState } from '../revenueInvoiceState';

export const onCreateRevenueInvoice = makeFetchStatusReducers<
	RevenueInvoiceState,
	'fetchStatuses',
	CreateRevenueInvoiceRequest,
	CreateRevenueInvoiceResponse
>('fetchStatuses', 'create', {
	doneReducer: (state, { result }) => ({
		...state,
		data: [...state.data, result]
	})
});
