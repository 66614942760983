import { createSelector } from 'reselect';
import { FetchStatus } from 'services/api/apiTypes';
import { AppState } from 'store-types';

const getCostCentresState = (state: AppState) => state.costCentres;

export const getCostCentresHasMore = (state: AppState) =>
	getCostCentresState(state).hasMore;

export const getCostCentresData = (state: AppState) =>
	getCostCentresState(state).data;

export const getRetrieveCostCentresIsFetching = (state: AppState) =>
	getCostCentresState(state).fetchStatuses.retrieve === FetchStatus.PENDING;

export const getRetrieveCostCentresCount = createSelector(
	getCostCentresData,
	costCentres => costCentres.length
);

export const getCreateCostCentreIsFetching = (state: AppState) =>
	getCostCentresState(state).fetchStatuses.create === FetchStatus.PENDING;

export const getUpdateCostCentreIsFetching = (state: AppState) =>
	getCostCentresState(state).fetchStatuses.update === FetchStatus.PENDING;

export const getCostCentreById = createSelector(
	getCostCentresData,
	(_state: AppState, id: string) => id,
	(costCentres, id) => costCentres.find(costCentre => costCentre.id === id)
);
