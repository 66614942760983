import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { EntitiesState } from '../entitiesState';
import {
	UpdateEntityRequest,
	Entity
} from 'services/api/entities/entitiesServiceTypes';
import { map } from 'lodash';

export const onUpdateEntity = makeFetchStatusReducers<
	EntitiesState,
	'fetchStatuses',
	UpdateEntityRequest,
	Entity
>('fetchStatuses', 'update', {
	doneReducer: (state, { result }) => ({
		...state,
		data: map(state.data, (entity: Entity) =>
			entity.id === result.id ? result : entity
		)
	})
});
