import {
	RetrieveEntityRequest,
	RetrieveEntityResponse
} from 'services/api/entities/entitiesServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

import Api from 'services/api';
import { retrieveEntitiesAsync } from '../actions/retrieveEntities';

export default makeTakeLatestWatcher<
	RetrieveEntityRequest,
	RetrieveEntityResponse,
	Error
>({
	api: Api.Entities.retrieveEntity,
	async: retrieveEntitiesAsync
});
