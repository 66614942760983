import Api from 'services/api';
import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { notify } from 'store/notifications/actions';
import { AxiosResponse } from 'axios';
import {
	CreateRevenueInvoiceRequest,
	CreateRevenueInvoiceResponse
} from 'services/api/revenueInvoice/revenueInvoiceServiceTypes';
import { createRevenueInvoice, createRevenueInvoiceAsync } from '../actions';

export function* worker({
	payload
}: Action<CreateRevenueInvoiceRequest>): SagaIterator {
	yield put(createRevenueInvoiceAsync.started(payload));
	try {
		const response: AxiosResponse<CreateRevenueInvoiceResponse> = yield call(
			Api.RevenueInvoice.createRevenueInvoice,
			payload
		);
		if (response.status === 201) {
			yield put(
				notify.success('A new Revenue Invoice has been successfully created.')
			);
		}
		yield put(
			createRevenueInvoiceAsync.done({
				result: response.data,
				params: payload,
				response
			})
		);
	} catch (error) {
		yield put(
			createRevenueInvoiceAsync.failed({
				error,
				params: payload
			})
		);
	}
}

function* watcher(): SagaIterator {
	yield takeLatest(createRevenueInvoice, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
