import { all, fork } from 'redux-saga/effects';
import retrieveCostCentresSaga from './retrieveCostCentresSaga';
import deleteCostCentreModalSaga from './deleteCostCentreModalSaga';
import deleteCostCentreSaga from './deleteCostCentreSaga';

export default function*() {
	yield all([fork(retrieveCostCentresSaga)]);
	yield all([fork(deleteCostCentreModalSaga)]);
	yield all([fork(deleteCostCentreSaga)]);
}
