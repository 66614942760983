import actionCreator from '../entitiesActionCreator';
import {
	RetrieveEntityRequest,
	RetrieveEntityResponse
} from 'services/api/entities/entitiesServiceTypes';

const ACTION_NAME = 'RETRIEVE_ENTITIES';

export const retrieveEntitiesAsync = actionCreator.async<
	RetrieveEntityRequest,
	RetrieveEntityResponse,
	Error
>(ACTION_NAME);

export const retrieveEntities = actionCreator<
	RetrieveEntityRequest | undefined
>(ACTION_NAME);

export const resetEntities = actionCreator('RESET_ENTITIES');
