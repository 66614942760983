import * as React from 'react';
import {
	CustodyRole,
	PortJob
} from 'services/api/portJobs/portJobsServiceTypes';
import { Select, MaybeTooltip } from 'components/antd';
import { connect } from 'react-redux';
import {
	getCustodyRoles,
	getActivePortJobCode
} from 'store/portJobs/selectors';
import { retrievePortJobCustodyRoles } from 'store/portJobs/actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { isEqual } from 'lodash';
import { AppState } from 'store-types';

interface CustodyRoleSelectProps {
	onSelect: (custodyRoleCode: CustodyRole['code']) => void;
	// from mapStateToProps
	custodyRoles: CustodyRole[];
	activePortCallId: PortCall['id'];
	activePortJobCode: PortJob['code'];
	// from mapDispatchToProps
	retrievePortJobCustodyRoles: typeof retrievePortJobCustodyRoles;
	disabled?: boolean;
}

interface CustodyRoleSelectState {
	selected: CustodyRole['code'];
}

const getDocumentBody = () => document.body;

class CustodyRoleSelect extends React.PureComponent<
	CustodyRoleSelectProps,
	CustodyRoleSelectState
> {
	constructor(props: CustodyRoleSelectProps) {
		super(props);
		this.state = {
			selected: ''
		};
	}

	componentDidMount() {
		const { activePortJobCode } = this.props;
		this.retrievePortJobCustodyRoles(activePortJobCode);
	}

	componentDidUpdate(prevProps: CustodyRoleSelectProps) {
		const { activePortJobCode, custodyRoles } = this.props;
		if (this.props.activePortJobCode !== prevProps.activePortJobCode) {
			this.retrievePortJobCustodyRoles(activePortJobCode);
		}
		if (!isEqual(custodyRoles, prevProps.custodyRoles)) {
			this.setState({ selected: '' });
		}
	}

	retrievePortJobCustodyRoles = (jobCode: string) =>
		this.props.retrievePortJobCustodyRoles({
			portCallId: this.props.activePortCallId,
			jobCode
		});

	onSelect = (custodyRoleCode: string) => {
		this.setState({ selected: custodyRoleCode });
		this.props.onSelect(custodyRoleCode);
	};

	render() {
		const { custodyRoles, disabled = false } = this.props;
		const { selected } = this.state;
		const agencyRolesCodesToExclude = ['Inbound', 'Outbound'];
		return (
			<Select
				placeholder="Select VP Custody"
				onSelect={this.onSelect}
				disabled={disabled}
				{...(selected ? { value: selected } : {})}
			>
				{custodyRoles
					.filter(item => !agencyRolesCodesToExclude.includes(item.code))
					.map(role => (
						<Select.Option
							key={role.code}
							value={role.code}
							disabled={!role.isAllowed}
						>
							<MaybeTooltip
								show={!role.isAllowed}
								title="Selected VP Custody role contradicts port call's configuration: check other agents custody roles"
								placement="left"
								getPopupContainer={getDocumentBody}
							>
								<div>{role.name}</div>
							</MaybeTooltip>
						</Select.Option>
					))}
			</Select>
		);
	}
}

export default connect(
	(state: AppState) => ({
		custodyRoles: getCustodyRoles(state),
		activePortCallId: getActivePortCallId(state),
		activePortJobCode: getActivePortJobCode(state)
	}),
	{
		retrievePortJobCustodyRoles
	}
)(CustodyRoleSelect);
