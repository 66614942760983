import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { InterdepartmentalServicesState } from '../interdepartmentalServicesState';
import {
	DeleteInterdepartmentalServiceRequest,
	DeleteInterdepartmentalServiceResponse
} from 'services/api/interdepartmentalService/interdepartmentalServiceTypes';
import { Success } from 'typescript-fsa';

export const onDeleteInterdepartmentalService = makeFetchStatusReducers<
	InterdepartmentalServicesState,
	'fetchStatuses',
	DeleteInterdepartmentalServiceRequest,
	DeleteInterdepartmentalServiceResponse
>('fetchStatuses', 'delete', {
	doneReducer: (
		state: InterdepartmentalServicesState,
		action: Success<
			DeleteInterdepartmentalServiceRequest,
			DeleteInterdepartmentalServiceResponse
		>
	): InterdepartmentalServicesState => ({
		...state,
		data:
			state.data.filter(
				interdepartmentalService =>
					interdepartmentalService.id !== action.params.id
			) || []
	})
});
