import Api from 'services/api';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { deleteVarianceCommentAsync } from '../actions/deleteVarianceComment';
import { call, put, takeLatest } from 'redux-saga/effects';
import { retrieveVarianceCommentsAsync } from '../actions';

export const worker = function*({
	payload
}: Action<{ varianceCommentId: string; jobFinanceId: string }>): SagaIterator {
	const { varianceCommentId, jobFinanceId } = payload;

	yield put(deleteVarianceCommentAsync.started(varianceCommentId));

	try {
		const response = yield call(
			Api.Finance.deleteVarianceComment,
			varianceCommentId
		);
		yield put(
			deleteVarianceCommentAsync.done({
				result: response.data,
				params: varianceCommentId,
				response
			})
		);

		const retrieveResponse = yield call(
			Api.Finance.retrievePortJobVarianceCommentsForJobFinance,
			jobFinanceId
		);

		yield put(
			retrieveVarianceCommentsAsync.done({
				result: retrieveResponse.data,
				params: jobFinanceId,
				response: retrieveResponse
			})
		);
	} catch (error) {
		yield put(
			deleteVarianceCommentAsync.failed({
				error,
				params: varianceCommentId
			})
		);
	}
};

export default function* deleteVarianceCommentWatcher(): SagaIterator {
	yield takeLatest(deleteVarianceCommentAsync.type, worker);
}
