import Api from 'services/api';
import {
	CreateVarianceCommentPayload,
	CreateVarianceCommentRequest
} from 'services/api/finance/financeServiceTypes';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { addVarianceCommentAsync } from '../actions/addVarianceComment';
import { call, put, takeLatest } from 'redux-saga/effects';
import { retrieveVarianceCommentsAsync } from '../actions';

export const worker = function*({
	payload
}: Action<CreateVarianceCommentPayload>): SagaIterator {
	yield put(addVarianceCommentAsync.started(payload));

	try {
		const request = {
			payload: payload
		} as CreateVarianceCommentRequest;
		const response = yield call(Api.Finance.addVarianceComment, request);
		yield put(
			addVarianceCommentAsync.done({
				result: response.data,
				params: payload,
				response
			})
		);

		const retrieveResponse = yield call(
			Api.Finance.retrievePortJobVarianceCommentsForJobFinance,
			payload.jobFinanceId
		);

		yield put(
			retrieveVarianceCommentsAsync.done({
				result: retrieveResponse.data,
				params: payload.jobFinanceId,
				response: retrieveResponse
			})
		);
	} catch (error) {
		yield put(
			addVarianceCommentAsync.failed({
				error,
				params: payload
			})
		);
	}
};

export default function* addVarianceCommentWatcher(): SagaIterator {
	yield takeLatest(addVarianceCommentAsync.type, worker);
}
