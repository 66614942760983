import {
	UpdateEntityRequest,
	Entity
} from 'services/api/entities/entitiesServiceTypes';
import actionCreator from '../entitiesActionCreator';

const ACTION_NAME = 'UPDATE_ENTITY';

export const updateEntityAsync = actionCreator.async<
	UpdateEntityRequest,
	Entity,
	Error
>(ACTION_NAME);

export const updateEntity = actionCreator<UpdateEntityRequest>(ACTION_NAME);
