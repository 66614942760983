import actionCreator from '../revenueInvoiceActionCreator';
import {
	RevenueInvoice,
	UpdateRevenueInvoiceRequest
} from 'services/api/revenueInvoice/revenueInvoiceServiceTypes';

const ACTION_NAME = 'UPDATE_REVENUE_INVOICE';
export const updateRevenueInvoiceAsync = actionCreator.async<
	UpdateRevenueInvoiceRequest,
	RevenueInvoice,
	Error
>(ACTION_NAME);
export const updateRevenueInvoice = actionCreator<UpdateRevenueInvoiceRequest>(
	ACTION_NAME
);
