import {
	RetrieveCostCentreRequest,
	RetrieveCostCentreResponse
} from 'services/api/costCentre/costCentreServiceTypes';
import actionCreator from '../costCentresActionCreator';

const ACTION_NAME = 'RETRIEVE_COST_CENTRES';

export const retrieveCostCentresAsync = actionCreator.async<
	RetrieveCostCentreRequest,
	RetrieveCostCentreResponse,
	Error
>(ACTION_NAME);

export const retrieveCostCentres = actionCreator<
	RetrieveCostCentreRequest | undefined
>(ACTION_NAME);

export const resetCostCentres = actionCreator('RESET_COST_CENTRES');
