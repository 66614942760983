import client from 'services/client';
import {
	CostCentreSearchResponse,
	CreateCostCentreRequest,
	CreateCostCentreResponse,
	DeleteCostCentreRequest,
	RetrieveCostCentreRequest,
	RetrieveCostCentreResponse,
	UpdateCostCentreRequest,
	UpdateCostCentreResponse
} from './costCentreServiceTypes';
import { AxiosPromise } from 'axios';

const searchCostCentre = (
	operatingEntityCode: string,
	searchTerm: string
): AxiosPromise<CostCentreSearchResponse> =>
	client.get(
		`masterdata/cost-centres/search?operatingEntityCode=${operatingEntityCode}&searchTerm=${encodeURIComponent(
			searchTerm
		)}`
	);

const retrieveCostCentres = ({
	index,
	sortBy,
	limit
}: RetrieveCostCentreRequest): AxiosPromise<RetrieveCostCentreResponse> =>
	client.get('masterdata/cost-centres/mappings', {
		params: {
			sortBy,
			limit,
			index
		}
	});

const createCostCentre = (
	data: CreateCostCentreRequest
): AxiosPromise<CreateCostCentreResponse> =>
	client.post(`masterdata/cost-centres/mappings`, data);

const updateCostCentre = ({
	id,
	...data
}: UpdateCostCentreRequest): AxiosPromise<UpdateCostCentreResponse> =>
	client.put(`masterdata/cost-centres/mappings/${id}`, data);

const deleteCostCentre = ({
	id
}: DeleteCostCentreRequest): AxiosPromise<DeleteCostCentreRequest> =>
	client.delete(`masterdata/cost-centres/mappings/${id}`);

export default {
	searchCostCentre,
	retrieveCostCentres,
	createCostCentre,
	updateCostCentre,
	deleteCostCentre
};
