import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { EntitiesState } from '../entitiesState';
import {
	CreateEntityRequest,
	CreateEntityResponse
} from 'services/api/entities/entitiesServiceTypes';

export const onCreateEntity = makeFetchStatusReducers<
	EntitiesState,
	'fetchStatuses',
	CreateEntityRequest,
	CreateEntityResponse
>('fetchStatuses', 'create', {
	doneReducer: (state, { result }) => ({
		...state,
		data: [...state.data, result]
	})
});
