import { FetchStatus } from 'services/api/apiTypes';
import { RevenueInvoice } from '../../services/api/revenueInvoice/revenueInvoiceServiceTypes';

export interface RevenueInvoiceState {
	hasMore: boolean;
	data: Array<RevenueInvoice>;
	isDuplicateRecord: boolean;
	fetchStatuses: {
		retrieve: FetchStatus;
		delete: FetchStatus;
		create: FetchStatus;
		update: FetchStatus;
	};
}

export const initialState: RevenueInvoiceState = {
	hasMore: true,
	data: [],
	isDuplicateRecord: false,
	fetchStatuses: {
		retrieve: FetchStatus.IDLE,
		delete: FetchStatus.IDLE,
		create: FetchStatus.IDLE,
		update: FetchStatus.IDLE
	}
};
