import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { deleteCostCentreAsync } from '../actions';
import {
	DeleteCostCentreRequest,
	DeleteCostCentreResponse
} from 'services/api/costCentre/costCentreServiceTypes';

export default makeTakeLatestWatcher<
	DeleteCostCentreRequest,
	DeleteCostCentreResponse,
	Error
>({ api: Api.CostCentre.deleteCostCentre, async: deleteCostCentreAsync });
