import {
	DeleteEntityRequest,
	DeleteEntityResponse
} from 'services/api/entities/entitiesServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { deleteEntityAsync } from '../actions';
import Api from 'services/api';

export default makeTakeLatestWatcher<
	DeleteEntityRequest,
	DeleteEntityResponse,
	Error
>({
	api: Api.Entities.deleteEntity,
	async: deleteEntityAsync
});
