import actionCreator from '../revenueInvoiceActionCreator';
import {
	CreateRevenueInvoiceRequest,
	CreateRevenueInvoiceResponse
} from 'services/api/revenueInvoice/revenueInvoiceServiceTypes';

const ACTION_NAME = 'CREATE_REVENUE_INVOICE';

export const createRevenueInvoiceAsync = actionCreator.async<
	CreateRevenueInvoiceRequest,
	CreateRevenueInvoiceResponse,
	Error
>(ACTION_NAME);

export const createRevenueInvoice = actionCreator<CreateRevenueInvoiceRequest>(
	ACTION_NAME
);
