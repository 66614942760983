import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { initialState } from './revenueInvoiceState';
import {
	retrieveRevenueInvoicesAsync,
	resetRevenueInvoices,
	deleteRevenueInvoiceAsync,
	createRevenueInvoiceAsync,
	updateRevenueInvoiceAsync
} from './actions';
import {
	onRetrieveRevenueInvoices,
	onResetRevenueInvoices,
	onCreateRevenueInvoice,
	onDeleteRevenueReducer,
	onUpdateRevenueInvoice
} from './reducers';

export default reducerWithInitialState(initialState)
	// Retrieve
	.case(retrieveRevenueInvoicesAsync.started, onRetrieveRevenueInvoices.started)
	.case(retrieveRevenueInvoicesAsync.done, onRetrieveRevenueInvoices.done)
	.case(retrieveRevenueInvoicesAsync.failed, onRetrieveRevenueInvoices.failed)
	// Delete
	.case(deleteRevenueInvoiceAsync.started, onDeleteRevenueReducer.started)
	.case(deleteRevenueInvoiceAsync.done, onDeleteRevenueReducer.done)
	.case(deleteRevenueInvoiceAsync.failed, onDeleteRevenueReducer.failed)
	// Create
	.case(createRevenueInvoiceAsync.started, onCreateRevenueInvoice.started)
	.case(createRevenueInvoiceAsync.done, onCreateRevenueInvoice.done)
	.case(createRevenueInvoiceAsync.failed, onCreateRevenueInvoice.failed)
	// Update
	.case(updateRevenueInvoiceAsync.started, onUpdateRevenueInvoice.started)
	.case(updateRevenueInvoiceAsync.done, onUpdateRevenueInvoice.done)
	.case(updateRevenueInvoiceAsync.failed, onUpdateRevenueInvoice.failed)
	// Reset
	.case(resetRevenueInvoices, onResetRevenueInvoices);
