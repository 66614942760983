import { createSelector } from 'reselect';
import { FetchStatus } from 'services/api/apiTypes';
import { AppState } from 'store-types';

const getEntitiesState = (state: AppState) => state.entities;

export const getEntitiesHasMore = (state: AppState) =>
	getEntitiesState(state).hasMore;

export const getEntitiesData = (state: AppState) =>
	getEntitiesState(state).data;

export const getRetrieveEntitiesIsFetching = (state: AppState) =>
	getEntitiesState(state).fetchStatuses.retrieve === FetchStatus.PENDING;

export const getCreateEntityIsFetching = (state: AppState) =>
	getEntitiesState(state).fetchStatuses.create === FetchStatus.PENDING;

export const getDeleteEntityIsFetching = (state: AppState) =>
	getEntitiesState(state).fetchStatuses.delete === FetchStatus.PENDING;

export const getUpdateEntityIsFetching = (state: AppState) =>
	getEntitiesState(state).fetchStatuses.update === FetchStatus.PENDING;

export const getRetrieveEntitiesCount = createSelector(
	getEntitiesData,
	entities => entities.length
);

export const getEntityById = createSelector(
	getEntitiesData,
	(_state: AppState, id: string) => id,
	(entities, id) => entities.find(entity => entity.id === id)
);
