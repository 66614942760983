import bankAccountsService from './bankAccounts/bankAccountsService';
import commoditiesService from './commodities/commoditiesService';
import companiesService from './companies/companiesService';
import countriesService from './countries/countriesService';
import currenciesService from './currencies/currenciesService';
import documentsService from './documents/documentsService';
import groupsService from './groups/groupsService';
import mailboxesService from './mailboxes/mailboxesService';
import portCallsService from './portCalls/portCallsService';
import portCallService from './portCall/portCallService';
import portCallDocumentsService from './portCallDocuments/portCallDocumentsService';
import portsService from './ports/portsService';
import portJobsService from './portJobs/portJobsService';
import registrationService from './registration/registrationService';
import supplierInvoiceService from './supplierInvoices/supplierInvoicesService';
import threadsService from './threads/threadsService';
import templatesService from './templates/templatesService';
import messagesService from './messages/messagesService';
import vesselsService from './vessels/vesselsService';
import operationsService from './operations/operationsService';
import unitsService from './units/unitsService';
import usersService from './users/usersService';
import vesselProgrammeService from './vesselProgramme/vesselProgrammeService';
import anchorageService from 'services/api/anchorage/anchorageService';
import financeService from 'services/api/finance/financeService';
import retrieveSavings from 'services/api/savings/savingsService';
import financeFundingsService from 'services/api/financeFundings/financeFundingsService';
import permissionsService from 'services/api/permissions/permissionsService';
import dashboardService from './dashboard/dashboardService';
import regionsService from './regions/regionsService';
import directBills from './directBills/directBillsService';
import portCallMeasurementsService from './portCallMeasurements/portCallMeasurementsService';
import servicesService from './services/servicesService';
import opticConfigService from './opticConfiguration/opticConfigService';
import developerPortalService from './developerPortal/developerPortalService';
import entitiesService from './entities/entitiesService';
import interdepartmentalService from './interdepartmentalService/interdepartmentalService';
import costCentreService from './costCentre/costCentreService';
import mainPrincipalsService from './mainPrincipalTypes/mainPrincipalTypesService';
import revenueInvoiceService from './revenueInvoice/revenueInvoiceService';

export default {
	Anchorages: anchorageService,
	BankAccounts: bankAccountsService,
	Commodities: commoditiesService,
	Companies: companiesService,
	CostCentre: costCentreService,
	Countries: countriesService,
	Currencies: currenciesService,
	Dashboard: dashboardService,
	DeveloperPortal: developerPortalService,
	DirectBills: directBills,
	Documents: documentsService,
	Finance: financeService,
	Savings: retrieveSavings,
	FinanceFundings: financeFundingsService,
	Groups: groupsService,
	Mailboxes: mailboxesService,
	MainPrincipalTypes: mainPrincipalsService,
	Messages: messagesService,
	Operations: operationsService,
	OpticConfiguration: opticConfigService,
	PortCallDocuments: portCallDocumentsService,
	PortCallMeasurements: portCallMeasurementsService,
	Permissions: permissionsService,
	PortCall: portCallService,
	PortCalls: portCallsService,
	PortJobs: portJobsService,
	Ports: portsService,
	Registration: registrationService,
	SupplierInvoice: supplierInvoiceService,
	Threads: threadsService,
	Templates: templatesService,
	Units: unitsService,
	Users: usersService,
	VesselProgramme: vesselProgrammeService,
	Vessels: vesselsService,
	Regions: regionsService,
	Services: servicesService,
	Entities: entitiesService,
	InterdepartmentalServices: interdepartmentalService,
	RevenueInvoice: revenueInvoiceService
};
