import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { CostCentreState } from '../costCentreState';
import {
	UpdateCostCentrePayload,
	UpdateCostCentreResponse,
	CostCentre
} from 'services/api/costCentre/costCentreServiceTypes';
import { map } from 'lodash';
import { AxiosError } from 'axios';

export const onUpdateCostCentre = makeFetchStatusReducers<
	CostCentreState,
	'fetchStatuses',
	UpdateCostCentrePayload,
	UpdateCostCentreResponse,
	AxiosError
>('fetchStatuses', 'update', {
	doneReducer: (state, { params }) => ({
		...state,
		data: map(state.data, (cost: CostCentre) =>
			cost.id === params.costCentre.id ? params.costCentre : cost
		)
	})
});
