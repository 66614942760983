import { all, fork } from 'redux-saga/effects';
import retrieveEntitiesSaga from './retrieveEntitiesSaga';
import deleteEntityModalSaga from './deleteEntityModalSaga';
import deleteEntitySaga from './deleteEntitySaga';
import createEntitySaga from './createEntitySaga';
import updateEntitySaga from './updateEntitySaga';

export default function*() {
	yield all([fork(retrieveEntitiesSaga)]);
	yield all([fork(deleteEntityModalSaga)]);
	yield all([fork(deleteEntitySaga)]);
	yield all([fork(createEntitySaga)]);
	yield all([fork(updateEntitySaga)]);
}
