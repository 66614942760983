import client from 'services/client';
import {
	CreateEntityRequest,
	CreateEntityResponse,
	DeleteEntityRequest,
	DeleteEntityResponse,
	Entity,
	RetrieveEntityResponse,
	RetrieveLiveOperatingEntitiesResponse,
	RetrieveOperatingEntitiesResponse,
	UpdateEntityRequest
} from './entitiesServiceTypes';
import { AxiosPromise } from 'axios';

const retrieveEntity = (): AxiosPromise<RetrieveEntityResponse> =>
	client.get('live-entities');

const searchLiveOperatingEntities = (
	searchTerm: string
): AxiosPromise<RetrieveLiveOperatingEntitiesResponse> =>
	client.get(
		`live-entities/operating-entities/search?searchTerm=${searchTerm}&limit=`
	);

const searchOperatingEntities = (
	searchTerm: string
): AxiosPromise<RetrieveOperatingEntitiesResponse> =>
	client.get(
		`masterdata/operating-entities/search?index=0&searchTerm=${searchTerm}&limit=`
	);

const createEntity = (
	data: CreateEntityRequest
): AxiosPromise<CreateEntityResponse> => client.post(`live-entities`, data);

const updateEntity = ({
	id,
	operatingEntityId,
	liveDate
}: UpdateEntityRequest): AxiosPromise<Entity> =>
	client.put(`live-entities/${id}`, { operatingEntityId, liveDate });

const deleteEntity = ({
	id
}: DeleteEntityRequest): AxiosPromise<DeleteEntityResponse> =>
	client.delete(`live-entities/${id}`);

export default {
	retrieveEntity,
	searchLiveOperatingEntities,
	searchOperatingEntities,
	createEntity,
	updateEntity,
	deleteEntity
};
