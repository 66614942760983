import {
	InterdepartmentalService,
	UpdateInterdepartmentalServiceRequest
} from 'services/api/interdepartmentalService/interdepartmentalServiceTypes';
import Api from 'services/api';
import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { notify } from 'store/notifications/actions';
import { AxiosResponse } from 'axios';
import {
	updateInterdepartmentalService,
	updateInterdepartmentalServiceAsync
} from '../actions/updateInterdepartmentalService';

export function* worker({
	payload
}: Action<UpdateInterdepartmentalServiceRequest>): SagaIterator {
	yield put(updateInterdepartmentalServiceAsync.started(payload));

	try {
		const response: AxiosResponse<InterdepartmentalService> = yield call(
			Api.InterdepartmentalServices.updateInterdepartmentalService,
			payload
		);

		if (response.status === 200) {
			yield put(
				notify.success(
					`${response.data.operatingEntityCode} Operating InterdepartmentalService has been successfully updated.`
				)
			);

			yield put(
				updateInterdepartmentalServiceAsync.done({
					result: response.data,
					params: payload,
					response
				})
			);
		}
	} catch (error) {
		yield put(
			updateInterdepartmentalServiceAsync.failed({
				error,
				params: payload
			})
		);
	}
}

function* watcher(): SagaIterator {
	yield takeLatest(updateInterdepartmentalService, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
