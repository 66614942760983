import { DeleteVarianceCommentResponse } from 'services/api/finance/financeServiceTypes';
import actionCreator from '../financeActionCreator';

const DELETE_VARIANCE_COMMENT = 'DELETE_VARIANCE_COMMENT';

export const deleteVarianceCommentAsync = actionCreator.async<
	string, // portJobServiceVarianceCommentId
	DeleteVarianceCommentResponse,
	Error
>(DELETE_VARIANCE_COMMENT, {
	templateParams: {
		entityName: 'Variance Comment'
	}
});

export const deleteVarianceComment = actionCreator<{
	varianceCommentId: string;
	jobFinanceId: string;
}>(DELETE_VARIANCE_COMMENT);
