import {
	UpdateInterdepartmentalServiceRequest,
	InterdepartmentalService
} from 'services/api/interdepartmentalService/interdepartmentalServiceTypes';
import actionCreator from '../interdepartmentalServiceActionCreator';

const ACTION_NAME = 'UPDATE_INTERDEPARTMENTAL_SERVICES';

export const updateInterdepartmentalServiceAsync = actionCreator.async<
	UpdateInterdepartmentalServiceRequest,
	InterdepartmentalService,
	Error
>(ACTION_NAME);

export const updateInterdepartmentalService = actionCreator<
	UpdateInterdepartmentalServiceRequest
>(ACTION_NAME);
