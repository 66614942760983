import { FetchStatus } from 'services/api/apiTypes';
import { MainPrincipalType } from 'services/api/mainPrincipalTypes/mainPrincipalTypesServiceTypes';

export interface MainPrincipalTypesState {
	data: MainPrincipalType[];
	fetchStatuses: {
		retrieve: FetchStatus;
	};
}

export const initialState: MainPrincipalTypesState = {
	data: [],
	fetchStatuses: {
		retrieve: FetchStatus.IDLE
	}
};
