import { Success } from 'typescript-fsa';
import { RevenueInvoiceState, initialState } from '../revenueInvoiceState';
import {
	GetAllRevenueInvoicesRequest,
	GetAllRevenueInvoicesResponse
} from 'services/api/revenueInvoice/revenueInvoiceServiceTypes';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { FetchStatus } from 'services/api/apiTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRetrieveRevenueInvoiceStatesSuccess = (
	state: RevenueInvoiceState,
	action: Success<GetAllRevenueInvoicesRequest, GetAllRevenueInvoicesResponse>
): RevenueInvoiceState => {
	const {
		result: { elements }
	} = action;
	return {
		...state,
		hasMore: elements.length === DEFAULT_LIST_LIMIT,
		data: [...state.data, ...elements],
		fetchStatuses: { ...state.fetchStatuses, retrieve: FetchStatus.SUCCESS }
	};
};
export const onRetrieveRevenueInvoiceFailed = (
	state: RevenueInvoiceState
): RevenueInvoiceState => ({
	...state,
	hasMore: false
});
export const onRetrieveRevenueInvoices = makeFetchStatusReducers<
	RevenueInvoiceState,
	'fetchStatuses',
	GetAllRevenueInvoicesRequest,
	GetAllRevenueInvoicesResponse
>('fetchStatuses', 'retrieve', {
	doneReducer: onRetrieveRevenueInvoiceStatesSuccess,
	failedReducer: onRetrieveRevenueInvoiceFailed
});
export const onResetRevenueInvoices = (_state: RevenueInvoiceState) =>
	initialState;
