import actionCreator from '../revenueInvoiceActionCreator';
import {
	DeleteRevenueInvoiceRequest,
	DeleteRevenueInvoiceResponse
} from 'services/api/revenueInvoice/revenueInvoiceServiceTypes';

const ACTION_NAME = 'DELETE_REVENUE_INVOICE';

export interface DeleteRevenueInvoiceActionParams {
	id: string;
}

export const deleteRevenueInvoiceAsync = actionCreator.async<
	DeleteRevenueInvoiceRequest,
	DeleteRevenueInvoiceResponse,
	Error
>(ACTION_NAME);

export const deleteRevenueInvoice = actionCreator<
	DeleteRevenueInvoiceActionParams
>(ACTION_NAME);

export const deleteRevenueInvoiceModal = actionCreator<
	DeleteRevenueInvoiceActionParams
>(`${ACTION_NAME}_MODAL`);
