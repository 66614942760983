import {
	CreateEntityRequest,
	CreateEntityResponse
} from 'services/api/entities/entitiesServiceTypes';
import actionCreator from '../entitiesActionCreator';

const ACTION_NAME = 'CREATE_ENTITY';

export const createEntityAsync = actionCreator.async<
	CreateEntityRequest,
	CreateEntityResponse,
	Error
>(ACTION_NAME);

export const createEntity = actionCreator<CreateEntityRequest>(ACTION_NAME);
