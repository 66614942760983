import {
	put,
	select,
	takeLatest,
	all,
	fork,
	take,
	race
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
	deleteInterdepartmentalService,
	deleteInterdepartmentalServiceAsync,
	deleteInterdepartmentalServiceModal
} from '../actions';
import { getInterdepartmentalServiceById } from '../selectors';
import { Action } from 'typescript-fsa';
import { closeModal, openModal } from 'store/modals/actions';
import {
	DeleteInterdepartmentalServiceRequest,
	InterdepartmentalService
} from 'services/api/interdepartmentalService/interdepartmentalServiceTypes';

export function* worker({
	payload: { id }
}: Action<DeleteInterdepartmentalServiceRequest>): SagaIterator {
	const interdepartmentalService: InterdepartmentalService = yield select(
		getInterdepartmentalServiceById,
		id
	);
	const interdepartmentalServiceName = interdepartmentalService
		? interdepartmentalService.operatingEntityName
		: '';

	yield put(
		openModal({
			name: 'deleteInterdepartmentalService',
			type: 'confirm',
			data: {
				description: `Are you sure you want to delete the Interdepartmental Service ${interdepartmentalServiceName} ?`
			}
		})
	);
	const { payload } = yield take(closeModal.type);
	if (!payload.isConfirmed) {
		return;
	}

	yield put(deleteInterdepartmentalService({ id }));
	yield race({
		done: take(deleteInterdepartmentalServiceAsync.done),
		failed: take(deleteInterdepartmentalServiceAsync.failed)
	});
}

function* watcher(): SagaIterator {
	yield takeLatest(deleteInterdepartmentalServiceModal, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
