import { createSelector } from 'reselect';
import { FetchStatus } from 'services/api/apiTypes';
import { AppState } from 'store-types';

const getInterdepartmentalServiceState = (state: AppState) =>
	state.interdepartmentalService;

export const getInterdepartmentalServiceHasMore = (state: AppState) =>
	getInterdepartmentalServiceState(state).hasMore;

export const getInterdepartmentalServiceData = (state: AppState) =>
	getInterdepartmentalServiceState(state).data;

export const getRetrieveInterdepartmentalServiceIsFetching = (
	state: AppState
) =>
	getInterdepartmentalServiceState(state).fetchStatuses.retrieve ===
	FetchStatus.PENDING;

export const getCreateInterdepartmentalServiceIsFetching = (state: AppState) =>
	getInterdepartmentalServiceState(state).fetchStatuses.create ===
	FetchStatus.PENDING;

export const getDeleteInterdepartmentalServiceIsFetching = (state: AppState) =>
	getInterdepartmentalServiceState(state).fetchStatuses.delete ===
	FetchStatus.PENDING;

export const getUpdateInterdepartmentalServiceIsFetching = (state: AppState) =>
	getInterdepartmentalServiceState(state).fetchStatuses.update ===
	FetchStatus.PENDING;

export const getRetrieveInterdepartmentalServiceCount = createSelector(
	getInterdepartmentalServiceData,
	interdepartmentalService => interdepartmentalService.length
);

export const getInterdepartmentalServiceById = createSelector(
	getInterdepartmentalServiceData,
	(_state: AppState, id: string) => id,
	(interdepartmentalServices, id) =>
		interdepartmentalServices.find(
			interdepartmentalService => interdepartmentalService.id === id
		)
);
