import {
	DeleteEntityRequest,
	DeleteEntityResponse
} from 'services/api/entities/entitiesServiceTypes';
import actionCreator from '../entitiesActionCreator';
export interface DeleteEntityActionParams {
	id: string;
}
const ACTION_NAME = 'DELETE_ENTITY';

export const deleteEntityAsync = actionCreator.async<
	DeleteEntityRequest,
	DeleteEntityResponse,
	Error
>(ACTION_NAME);

export const deleteEntity = actionCreator<DeleteEntityActionParams>(
	ACTION_NAME
);

export const deleteEntityModal = actionCreator<DeleteEntityActionParams>(
	`${ACTION_NAME}_MODAL`
);
