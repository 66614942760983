import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';
import {
	DeleteRevenueInvoiceRequest,
	DeleteRevenueInvoiceResponse
} from 'services/api/revenueInvoice/revenueInvoiceServiceTypes';
import { RevenueInvoiceState } from '../revenueInvoiceState';

export const onDeleteRevenueReducer = makeFetchStatusReducers<
	RevenueInvoiceState,
	'fetchStatuses',
	DeleteRevenueInvoiceRequest,
	DeleteRevenueInvoiceResponse
>('fetchStatuses', 'delete', {
	doneReducer: (
		state: RevenueInvoiceState,
		action: Success<DeleteRevenueInvoiceRequest, DeleteRevenueInvoiceResponse>
	): RevenueInvoiceState => ({
		...state,
		data:
			state.data.filter(
				revenueInvoice => revenueInvoice.id !== action.params.id
			) || []
	})
});
