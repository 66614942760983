import { PortJobServiceVarianceComment } from 'services/api/finance/financeServiceTypes';
import actionCreator from '../financeActionCreator';

const RETRIEVE_VARIANCE_COMMENTS = 'GET_VARIANCE_COMMENTS';

export const retrieveVarianceCommentsAsync = actionCreator.async<
	string, //jobFinanceId
	PortJobServiceVarianceComment,
	Error
>(RETRIEVE_VARIANCE_COMMENTS);

export const retrieveVarianceComments = actionCreator<string>(
	RETRIEVE_VARIANCE_COMMENTS
);
