import Api from 'services/api';
import { retrieveMainPrincipalTypesAsync } from '../actions';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { RetrieveMainPrincipalTypesResponse } from 'services/api/mainPrincipalTypes/mainPrincipalTypesServiceTypes';

const retrieveMainPrincipalTypesWatcher = makeTakeLatestWatcher<
	null,
	RetrieveMainPrincipalTypesResponse,
	Error
>({
	api: Api.MainPrincipalTypes.retrieveMainPrincipalTypes,
	async: retrieveMainPrincipalTypesAsync
});

export default retrieveMainPrincipalTypesWatcher;
