import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { CostCentreState } from '../costCentreState';
import {
	DeleteCostCentreRequest,
	DeleteCostCentreResponse
} from 'services/api/costCentre/costCentreServiceTypes';
import { Success } from 'typescript-fsa';

export const onDeleteCostCentre = makeFetchStatusReducers<
	CostCentreState,
	'fetchStatuses',
	DeleteCostCentreRequest,
	DeleteCostCentreResponse
>('fetchStatuses', 'delete', {
	doneReducer: (
		state: CostCentreState,
		action: Success<DeleteCostCentreRequest, DeleteCostCentreResponse>
	): CostCentreState => ({
		...state,
		data: state.data.filter(cost => cost.id !== action.params.id) || []
	})
});
