import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	createCostCentreAsync,
	deleteCostCentreAsync,
	resetCostCentres,
	retrieveCostCentresAsync,
	updateCostCentreAsync
} from './actions';
import { initialState } from './costCentreState';
import {
	onResetCostCentres,
	onRetrieveCostCentres,
	onCreateCostCentre,
	onUpdateCostCentre,
	onDeleteCostCentre
} from './reducers';

export default reducerWithInitialState(initialState)
	// Retrieve
	.case(retrieveCostCentresAsync.started, onRetrieveCostCentres.started)
	.case(retrieveCostCentresAsync.done, onRetrieveCostCentres.done)
	.case(retrieveCostCentresAsync.failed, onRetrieveCostCentres.failed)
	// Reset
	.case(resetCostCentres, onResetCostCentres)
	// Delete
	.case(deleteCostCentreAsync.started, onDeleteCostCentre.started)
	.case(deleteCostCentreAsync.done, onDeleteCostCentre.done)
	.case(deleteCostCentreAsync.failed, onDeleteCostCentre.failed)
	// Create
	.case(createCostCentreAsync.started, onCreateCostCentre.started)
	.case(createCostCentreAsync.done, onCreateCostCentre.done)
	.case(createCostCentreAsync.failed, onCreateCostCentre.failed)
	// Update
	.case(updateCostCentreAsync.started, onUpdateCostCentre.started)
	.case(updateCostCentreAsync.done, onUpdateCostCentre.done)
	.case(updateCostCentreAsync.failed, onUpdateCostCentre.failed);
