import { Success } from 'typescript-fsa';
import { CostCentreState, initialState } from '../costCentreState';
import {
	RetrieveCostCentreRequest,
	RetrieveCostCentreResponse
} from 'services/api/costCentre/costCentreServiceTypes';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { FetchStatus } from 'services/api/apiTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { formatCostCentreData } from './utils';

export const onRetrieveCostCentresSuccess = (
	state: CostCentreState,
	action: Success<RetrieveCostCentreRequest, RetrieveCostCentreResponse>
): CostCentreState => {
	const {
		result: { elements }
	} = action;

	return {
		...state,
		hasMore: elements.length === DEFAULT_LIST_LIMIT,
		data: [...state.data, ...formatCostCentreData(elements)],
		fetchStatuses: { ...state.fetchStatuses, retrieve: FetchStatus.SUCCESS }
	};
};

export const onRetrieveCostCentresFailed = (
	state: CostCentreState
): CostCentreState => ({
	...state,
	hasMore: false
});

export const onRetrieveCostCentres = makeFetchStatusReducers<
	CostCentreState,
	'fetchStatuses',
	RetrieveCostCentreRequest,
	RetrieveCostCentreResponse
>('fetchStatuses', 'retrieve', {
	doneReducer: onRetrieveCostCentresSuccess,
	failedReducer: onRetrieveCostCentresFailed
});

export const onResetCostCentres = (_state: CostCentreState) => initialState;
