import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { initialState } from './interdepartmentalServicesState';

import {
	onCreateInterdepartmentalServices,
	onDeleteInterdepartmentalService,
	onResetInterdepartmentalServices,
	onRetrieveInterdepartmentalServices,
	onUpdateInterdepartmentalService
} from './reducers';
import {
	createInterdepartmentalServiceAsync,
	deleteInterdepartmentalServiceAsync,
	resetInterdepartmentalServices,
	retrieveInterdepartmentalServicesAsync,
	updateInterdepartmentalServiceAsync
} from './actions';

export default reducerWithInitialState(initialState)
	// Retrieve
	.case(
		retrieveInterdepartmentalServicesAsync.started,
		onRetrieveInterdepartmentalServices.started
	)
	.case(
		retrieveInterdepartmentalServicesAsync.done,
		onRetrieveInterdepartmentalServices.done
	)
	.case(
		retrieveInterdepartmentalServicesAsync.failed,
		onRetrieveInterdepartmentalServices.failed
	)
	// Reset
	.case(resetInterdepartmentalServices, onResetInterdepartmentalServices)
	// Delete
	.case(
		deleteInterdepartmentalServiceAsync.started,
		onDeleteInterdepartmentalService.started
	)
	.case(
		deleteInterdepartmentalServiceAsync.done,
		onDeleteInterdepartmentalService.done
	)
	.case(
		deleteInterdepartmentalServiceAsync.failed,
		onDeleteInterdepartmentalService.failed
	)
	// Create
	.case(
		createInterdepartmentalServiceAsync.started,
		onCreateInterdepartmentalServices.started
	)
	.case(
		createInterdepartmentalServiceAsync.done,
		onCreateInterdepartmentalServices.done
	)
	.case(
		createInterdepartmentalServiceAsync.failed,
		onCreateInterdepartmentalServices.failed
	)
	// Update
	.case(
		updateInterdepartmentalServiceAsync.started,
		onUpdateInterdepartmentalService.started
	)
	.case(
		updateInterdepartmentalServiceAsync.done,
		onUpdateInterdepartmentalService.done
	)
	.case(
		updateInterdepartmentalServiceAsync.failed,
		onUpdateInterdepartmentalService.failed
	);
